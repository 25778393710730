import React from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const withRouter = (WrappedComponent) => (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const selector = useSelector;
  const translate = useTranslation();
  const authenticated = useSelector((state) => state.auth.authenticated)



  return (
    <WrappedComponent
      {...props}
      params={params}
      translate={translate}
      navigate={navigate}
      location={location}
      dispatch={dispatch}
      authenticated={authenticated}
      selector = {selector}
    />
  );
};

export default withRouter;
