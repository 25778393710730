import { message } from 'antd';
import axios from 'axios';
import i18next from 'i18next';
import cookies from "js-cookie";
import { notiError } from '../constants/notificationConfig';
import { LOC_clear_token, LOC_getAccessToken, LOC_store_token } from '../core/custom-local-storage';
import Api from './api';
import { RefreshToken } from './authApi';
import { HTTP_METHOD } from './httpMethod';

const axiosInstance = axios.create({
    baseURL: Api.host,
    timeout: 120000
});

const axiosInstanceNoAuth = axios.create({
    baseURL: Api.host,
    timeout: 120000
});

axiosInstanceNoAuth.interceptors.request.use(
    async config => {
        const currentLanCode = cookies.get("i18next") || "en"
        config.headers = {
            'Accept-Language': currentLanCode,
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.request.use(
    async config => {
        const token = LOC_getAccessToken()
        const currentLanCode = cookies.get("i18next") || "en"
        config.headers = {
            'Authorization': `Bearer ${token}`,
            'Accept-Language': currentLanCode,
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (error.response.status === 401) {
            console.log("error 401")
            notiError("Error 401")
            try {
                const responseData = await RefreshToken();
                LOC_store_token(responseData)
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${responseData.accessToken}`;
                return axiosInstance(error.config);
            } catch (refreshError) {
                LOC_clear_token();
                this.props.authenticated = false;
                window.location.reload();
                this.props.navigate('/login')
            }
        } else if (error.response.status === 500) {
           notiError(error.response.data.message)

            if (error.response && error.response.data && error.response.data.code) {
                let code = error.response.data.code
                if (code === '045') {
                    message.error(i18next.t("user_address_duplicate"))
                } else {
                    throw error
                }
            }
        }

        return Promise.reject(error);
    }
);

export const ApiInstance = async (url, method, mediaType, requestData, requestParams, specificId, customMessage, showMessage) => {
    try {
        let responseData = null
        url = specificId ? url + `/${specificId}` : url
        const header = {
            headers: {
                'Content-Type': mediaType,  
            }
        }
        if (method === HTTP_METHOD.GET) {
            let response = null
            if (requestParams) {
                response = await axiosInstance.get(url, { params: requestParams })
            } else {
                response = await axiosInstance.get(url)
            }
            responseData = response?.data
        } else if (method === HTTP_METHOD.POST) {
            if (requestParams) {
                responseData = await axiosInstance.post(url, requestData, header, { params: requestParams })
            } else {
                responseData = await axiosInstance.post(url, requestData, header);
            }
        } else if (method === HTTP_METHOD.PUT) {
            responseData = await axiosInstance.put(url, requestData, header);
        } else if (method === HTTP_METHOD.DELETE) {
            responseData = await axiosInstance.delete(url);
        }

        if (showMessage) {
            if (customMessage) {
                message.destroy()
                message.success(customMessage)
            } else {
                if (method === HTTP_METHOD.POST) {
                    message.success("Successfully Created")
                } else if (method === HTTP_METHOD.PUT) {
                    message.success("Successfully Updated")
                } else if (method === HTTP_METHOD.DELETE) {
                    message.success("Successfully Deleted")
                }
            }
        }
        return responseData
    } catch (error) {
        throw error;
    }
}

export const ApiInstanceNoAuth = async (url, method, mediaType, requestData, specificId, requestParams, customMessage, showMessage) => {
    try {
        let responseData = null
        url = specificId ? url + `/${specificId}` : url
        const header = {
            headers: {
                'Content-Type': mediaType,
            }
        }
        if (method === HTTP_METHOD.GET) {
            let response = null
            if (requestParams) {
                response = await axiosInstance.get(url, { params: requestParams })
            } else {
                response = await axiosInstance.get(url)
            }
            responseData = response?.data
        } else if (method === HTTP_METHOD.POST) {
            if (requestParams) {
                responseData = await axiosInstance.post(url, requestData, header, { params: requestParams })
            } else {
                responseData = await axiosInstance.post(url, requestData, header);
            }
        } else if (method === HTTP_METHOD.PUT) {
            responseData = await axiosInstance.put(url, requestData, header);
        } else if (method === HTTP_METHOD.DELETE) {
            responseData = await axiosInstance.delete(url);
        }

        if (showMessage) {
            if (customMessage) {
                message.destroy()
                message.success(customMessage)
            } else {
                if (method === HTTP_METHOD.POST) {
                    message.success("Successfully Created")
                } else if (method === HTTP_METHOD.PUT) {
                    message.success("Successfully Updated")
                } else if (method === HTTP_METHOD.DELETE) {
                    message.success("Successfully Deleted")
                }
            }
        }
        return responseData
    } catch (error) {

        throw error;
    }
}


export const DownloadOrderFormatExcel = async () => {
    let responseData = null
    try {
        await axiosInstance.get(Api.download_order_sample_excel, {
            responseType: 'blob',
        }).then(response => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'Ninja Van Bulk Order Create Template.xlsx';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            responseData = response
        }).catch(error => {
            responseData = error
        });
    } catch (error) {
    }
    return responseData
}



