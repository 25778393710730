export const validatePhoneNumber = (rule, value) => {
    const phoneNumberRegex = /^(09|\d)\d{7,15}$/;
  
    if (!value) {
      return Promise.reject('Phone number is required');
    }
    if (!phoneNumberRegex.test(value)) {
      return Promise.reject('Phone number must start with 09 and be 10 digits long');
    }
    return Promise.resolve();
  };

  export  const validateLicensePlate = (_, value) => {
    if (!value || value.match(/^.{2}-\d{4}$/)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('Invalid license plate format'));
  };

  export const parseNRC = (nrc) => {
    const regex = /^(\d+)\/([A-Za-z]+)\((N|E)\)(\d{6})$/;
    const match = nrc.match(regex);
    if (match) {
      return {
        nrcCode: match[1],
        township: match[2],
        nrcType: match[3],
        nrcNumber: match[4],
      };
    }
    return null;
  };