const Theme = {
    colors: {
      primary: "#B6DCFE",
      secondary: "#f5f5f5", //search-box
      danger: "",
      success: "#28a745",
      buttonColor: "#000",
      b3: "##e0e0e0",
      bgPrimary: "#ffffff", //deep-background
      bgSecondary: "#ffffff", //Card-background,
      system_blue: '#4096ff'
    },
  };
  export default Theme;
  
  