import { compose } from '@reduxjs/toolkit';
import { Avatar, Card, Col, Descriptions, Divider, Row, Tabs } from 'antd';
import i18next from "i18next";
import React from 'react';
import { Link } from 'react-router-dom';
import companyLogo from "../../assets/images/Logo.jpeg";
import BreadCrumb from '../../components/common/BreadCrumb';
import { MvBackButton } from '../../components/common/Button/Button';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import CustomRoute from '../../routes/CustomRoute';
import DriverPage from '../Driver/DriverPage';
import FuelStationPage from '../FuelStation/FuelStationPage';
import VehiclePage from '../Vehicle/VehiclePage';
import "./manage.css";
import FuelPointRequest from '../FuelPointRequest/fuel-point-request';

export class CompanyManage extends React.Component {
    constructor(props) {
        super(props);
        const storedActiveKey = localStorage.getItem('activeKey') || 'driver';
        this.state = {
            activeKey: storedActiveKey,
            data: null,
            adminId: null,
            company: '',
            userType: '',
            companyTabs: null,
            stationTabs: null,
            items: [
                {
                    title: i18next.t("home"),
                },
                {
                    title: <Link to={CustomRoute.company}>{i18next.t("company")}</Link>,
                },
                {
                    title: i18next.t("company.detail"),
                },
            ],
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData();

    }

    componentDidUpdate(prevProps) {
        const { userType } = this.state
        
        if (this.props.location.search !== prevProps.location.search) {
            const params = new URLSearchParams(this.props.location.search);
            const routeActiveKey = params.get('tab');

            if (routeActiveKey && routeActiveKey !== this.state.activeKey && userType === "COMPANY_ADMIN") {
                localStorage.setItem('activeKey', routeActiveKey);
            }
        }
    }

    fetchData = async () => {
        const id = this.props.params.adminid
        try {
            const response = await ApiInstance(Api.admin, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, null, id);

            let company;
            company = { ...response.company };

            this.setState((prevState) => ({
                company: company,
                adminId: id,
                userType: response.userType,
                companyTabs: [
                    {
                        key: 'driver',
                        label: i18next.t("driver"),
                        children: <DriverPage companyId={company.id} adminId={id} />,
                    },
                    {
                        key: 'vehicle',
                        label: i18next.t('vehicle'),
                        children: <VehiclePage companyId={company.id} adminId={id} />,
                    },
                    {
                        key: 'fuelPointRequests',
                        label: i18next.t('fuel_point_requests'),
                        children: <FuelPointRequest companyId={company.id} adminId={id} />,
                    },
                ],
                stationTabs: [
                    {
                        key: 'fuelStation',
                        label: i18next.t("fuelStation"),
                        children: <FuelStationPage companyId={company.id} adminId={id} />,
                    },
                ]
            }));
            
        } catch (error) { }
    };

    onChange = (key) => {
        const { userType } = this.state

        if (userType === "COMPANY_ADMIN") {
            this.setState({ activeKey: key });
            localStorage.setItem('activeKey', key);
        }
    };

    render() {
        const { data, companyTabs, stationTabs, company, userType, activeKey } = this.state;
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <BreadCrumb items={this.state.items} />
                    </Col>
                    <Col lg={{ span: 12 }} style={{ textAlign: 'end' }}>
                        <MvBackButton
                            path={CustomRoute.company}
                            type="primary"
                            text={i18next.t("back")}
                        ></MvBackButton>
                    </Col>
                    <Col span={24}>
                        <Card className="detail-card"
                        >
                            <Row gutter={[0, 0]}>
                                <Col lg={{ span: 2 }}>
                                    <Avatar
                                        style={{
                                            boxShadow: '0 3px 7px 0 rgba(0, 0, 0, 0.3)',
                                            backgroundColor: 'gray',
                                            border: '1px solid #ffffff',
                                        }}
                                        src={companyLogo}
                                        crossOrigin='true'
                                        size={90}
                                    />
                                </Col>
                                <Col lg={{ span: 10 }} style={{ marginLeft: '10px' }}>
                                    <div style={{ marginTop: '15px' }}>
                                        <h3 style={{ margin: '0px' }}>{company.companyName}</h3>
                                        <p style={{ marginTop: '0px' }}>{company.emailAddress}</p>
                                    </div>
                                </Col>
                                <Col lg={{ span: 1 }}>
                                    <Divider type='vertical' style={{ height: "100%" }} />
                                </Col>
                                <Col lg={{ span: 9 }} style={{ textAlign: 'start' }}>
                                    <Row>
                                        <Descriptions
                                            column={1}
                                            layout="horizontal"
                                            colon={false}
                                        >
                                            <Descriptions.Item className='description-item' label={<span className="detail-label">{i18next.t("owner_name")}</span>}>
                                                <div className="detail-value">
                                                    <div>{company.ownerName}</div>
                                                </div>
                                            </Descriptions.Item>
                                            <Descriptions.Item className='description-item' label={<span className="detail-label">{i18next.t("registration_no")}</span>}>
                                                <div className="detail-value">
                                                    <div>{company.companyRegistrationNo}</div>
                                                </div>
                                            </Descriptions.Item>
                                            <Descriptions.Item className='description-item' label={<span className="detail-label">{i18next.t("phone_number")}</span>}>
                                                <div className="detail-value">
                                                    <div>{company.phoneNumber}</div>
                                                </div>
                                            </Descriptions.Item>
                                        </Descriptions>
                                    </Row>
                                </Col>
                                <Divider />
                                <Col lg={{ span: 24 }}>
                                    <Tabs className='manage-tabs' defaultActiveKey='driver' activeKey={userType === "FUEL_ADMIN" ? "fuelStation" : activeKey} items={userType === "COMPANY_ADMIN" ? companyTabs : userType === "FUEL_ADMIN" ? stationTabs : ''} onChange={this.onChange} style={{ backgroundColor: 'white', width: '100%' }} />
                                </Col>
                            </Row>
                        </Card>
                        <Row>

                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(
    withRouter
)(CompanyManage);