import { DownOutlined, EyeFilled, EyeOutlined } from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Button, Col, Divider, Dropdown, Image, Input, message, Modal, Row, Skeleton, Space, Table, Tag } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { EdSearchBox } from '../../components/core-component';
import { TitleLevel3 } from '../../components/general-component';
import { constant } from '../../constants/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';

class FuelPointRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      status: '',
      visible: false,
      reasonVisible: false,
      on_approve_loading: false,
      on_reject_loading: false,
      driverId: '',
      photo: '',
      data: [],
      page: constant.page,
      pageSize: constant.pageSize,
      totalPagination: '',
      searchValue: [],
      data_filter_params: '',
      prevSearchTerm: '',
      pageNumber: '',
      loading: true,
      remark: '',
      remarkVisible: false,
      reason: '', 
      requestId: '',
      requestScreenshotUrl: ''
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (pageNumber) => {
    const { page, user_filter_params, status } = this.state;
    const companyid = this.props.companyId

    this.setState({
      companyId: companyid
    })

    try {
      const params = {
        ...user_filter_params,
        companyId: this.props.companyId,
        page: pageNumber ? pageNumber - 1 : page - 1,
      };

      const response = await ApiInstance(Api.fuel_point_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)

      if (response && response.content) {
        this.setState((prevState) => ({
          data: response.content,
          totalPagination: response.totalElements,
          page: pageNumber ? pageNumber : page,
          loading: false,
        }));
      }
    } catch (error) { }
  };

  search = async (status) => {
    const { page, pageNumber, searchTerm, data_filter_params } = this.state
    this.setState({
      isDataFetching: true
    })
    try {
      const params = {
        ...data_filter_params,
        companyId: this.props.companyId,
        page: pageNumber ? pageNumber - 1 : page - 1,
        status: status
      };

      const response = await ApiInstance(Api.fuel_point_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
      if (response && response.content) {
        this.setState(prevState => ({
          data: response.content,
          totalPagination: response.totalElements,
          page: page,
          prevSearchTerm: searchTerm
        }));
      }
    } catch (error) {
    }
    this.setState({
      isDataFetching: false,
      statusType: status
    })
  }

  handlePaginationChange = (pageNumber, pageSize) => {
    this.fetchData(pageNumber);
  };

  changeSearchName = (event, searchName) => {
    let { data_filter_params } = this.state;

    data_filter_params = {
      ...data_filter_params,
      [searchName]: event.target.value,
    }

    this.setState({
      data_filter_params: data_filter_params,
      searchTerm: event.target
    });

    if (event.target.value === '' && this.state.prevSearchTerm) {
      this.setState({
        data_filter_params: {
          ...data_filter_params,
          [searchName]: '',
        },
        prevSearchTerm: ''
      }, () =>
        this.search()
      );
    }
  }

  handleKeyDown = (e) => {
    this.search();
  };


  handleScreenShot = async (request) => {
    console.log(request)
    try {
      this.setState({
        visible: true,
        driverId: request.id,
        requestScreenshotUrl: 'https://admin.edyamm.com/common?profile-url=' + request.paymentScreenShotUrl
      })
    } catch (error) {
      console.error(error)
    }
  }

  handleApprove = async (status, id) => {

    this.setState({
      on_approve_loading: true
    })

    const requestData = {
      "id": id,
      "approvalStatus": 'APPROVED',// APPROVED
    }

    try {
      const response = await ApiInstance(
        Api.fuel_point_approve, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
      if (response.status == 200) {
        this.setState({
          visible: false
        })
        message.success("Successfully Approved")
        this.fetchData()
      }

    } catch (error) {
      console.log(error);

      this.setState({
        on_approve_loading: false,
        visible: false
      })
    }
  };

  handleReject = async (status, id) => {
    this.setState({
      remarkVisible: true,
      requestId: id
    })

  };

  submitReject = async () => {
    const { remark, requestId } = this.state;

    console.log(remark)

    this.setState({
      on_reject_loading: true
    })

    const requestData = {
      "id": requestId,
      "approvalStatus": 'REJECT',
      "remark": remark
    }

    try {
      const response = await ApiInstance(
        Api.fuel_point_approve, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
      if (response.status == 200) {
        this.setState({
          on_reject_loading: false,
          remarkVisible: false,
          visible: false,
        })
        message.success("Successfully Reject")
        this.fetchData()
      }
    } catch (error) {
      console.log(error);

      this.setState({
        on_reject_loading: false,
        remarkVisible: false,
        visible: false,
      })
    }

  }


  render() {
    const { data, page, pageSize, visible, reasonVisible,
      requestScreenshotUrl, on_reject_loading, loading,
      totalPagination, remarkVisible, reason, statusType } = this.state

    const error = () => {
      Modal.error({
        title: 'This is an error message',
        content: 'some messages...some messages...',
      });
    };

    const columns = [
      {
        title: 'No.',
        key: 'index',
        render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
        width: 5
      },
      {
        title: i18next.t('driver_name'),
        dataIndex: ['driver', 'fullName'],
        key: 'fullName',
      },
      {
        title: i18next.t('phone_number'),
        dataIndex: ['driver', 'phoneNumber'],
        key: 'phoneNumber',
      },
      {
        title: i18next.t('top_up_point'),
        dataIndex: ['topUpPoint'],
        key: 'top_up_point',
      },
      {
        title: i18next.t('screen_shot'),
        key: 'screen_shot',
        dataIndex: '',
        render: (request) => {
          return (
            request.paymentScreenShotUrl ?
              <Space style={{ textAlign: 'end' }}>
                <Button onClick={() => this.handleScreenShot(request)} type="primary" shape="circle" icon={<EyeOutlined />} />
              </Space>
              : ''
          )
        },
      },
      {
        title: i18next.t('approve_action'),
        key: 'approveAction',
        dataIndex: '',
        // textAlign: 'center',
        width: 50,
        render: (request) => {
          return (
            request.paymentScreenShotUrl ?
              <Space style={{ textAlign: 'end' }}>
                {
                  request.approvalStatus === 'PENDING' ?
                  <>
                    <Button key="submit" type="primary" onClick={() => this.handleApprove("APPROVED", request.id)}>
                      {i18next.t('approve')}
                    </Button>
                    <Button type='primary' danger key="back" onClick={() => this.handleReject("REJECT", request.id)}>
                      {i18next.t('reject')}
                    </Button>
                  </>
                  : <>-</>
                }
              </Space>
              : ''
          )
        },
      },
      {
        title: i18next.t('status'),
        dataIndex: "approvalStatus",
        width: 150,
        key: 'status',
        render: (status) => {
          let color;
          switch (status) {
            case 'APPROVED':
              color = 'green';
              break;
            case 'REJECT':
              color = 'red';
              break;
            default:
              color = 'blue';
          }

          return (
            <Tag color={color} key={status}>
              {status}
            </Tag>
          );
        },
      },
      (data.some(item => item.remark === '' || item.remark === 'REJECT')) && {
        title: i18next.t('reject_reason'),
        dataIndex: 'remark',
        key: 'rejectReason',
        render: (remark) => {
          return (
            remark ?
              <a onClick={() => this.setState({ reasonVisible: true, reason: remark })}>View Reason</a>
              : ''
          );
        },
      }
    ].filter(Boolean);

    const items = [
      {
        key: "all",
        label: (
          <a
            href="#"
            onClick={() => {
              this.search('')
            }}
          >
            {i18next.t('all')}
          </a>
        ),
      },
      {
        key: "pending",
        label: (
          <a
            href="#"
            onClick={() => {
              this.search('PENDING')
            }}
          >
            {i18next.t('pending')}
          </a>
        ),
      },
      {
        key: "approved",
        label: (
          <a
            href="#"
            onClick={() => {
              this.search('APPROVED')
            }}
          >
            {i18next.t('approved')}
          </a>
        ),
      },
      {
        key: "reject",
        label: (
          <a
            href="#"
            onClick={() => {
              this.search('REJECT')
            }}
          >
            {i18next.t('reject')}
          </a>
        ),
      },
    ];

    return (
      <>
        <Modal
          title={<h3 style={{ margin: 0, }}>Request Screenshot</h3>}
          width={500}
          height={1200}
          visible={visible}
          onCancel={() => this.setState({ visible: false, requestScreenshotUrl: ''})}
          // onOk={this.handleApprove}
          footer={null}
          style={{
            top: 20,
          }}>
          <div style={{ display: 'flex', justifyContent: 'center', background: '#eee' }}>
            <Image preview={false} src={requestScreenshotUrl} />
          </div>
        </Modal>
        <Modal
          title="Remark"
          onCancel={() => this.setState({ remarkVisible: false })}
          visible={remarkVisible}
          footer={[
            <>
              <Button loading={on_reject_loading} onClick={this.submitReject} type='primary' danger>{i18next.t('reject')}</Button>
            </>
          ]}>
          <Input style={{ height: "40px" }} placeholder='e.g. Your screenshot is wrong....' onChange={(e) => this.setState({
            remark: e.target.value
          })}>

          </Input>
        </Modal>
        <Modal
          title={<span style={{ fontSize: 20, color: '#e33105'}}>Reject Reason</span>}
          onCancel={() => this.setState({ reasonVisible: false })}
          visible={reasonVisible}
          footer={null}>
            <Divider style={{margin: "0"}} />
          <span style={{ fontSize: 17 }}>{reason}</span>
        </Modal>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <TitleLevel3 label={i18next.t("fuel_point_requests")} />
          </Col>
          <Col span={4}><EdSearchBox
            size="middle"
            onChange={(values) =>
              this.changeSearchName(values, "name")}
            placeholder="SearchByName"
            onPressEnter={this.handleKeyDown}
          /></Col>
          <Col span={4}><EdSearchBox
            size="middle"
            onChange={(values) => this.changeSearchName(values, "phoneNumber")}
            placeholder="SearchByPhoneNumber"
            onPressEnter={this.handleKeyDown}
          /></Col>
          <Col span={12}>
            <Button size="large" shape="round" onClick={() => this.search()} type="primary" >{i18next.t('search')}</Button>
          </Col>
          <Col style={{ textAlign: 'end' }} lg={{ span: 4 }}>
            
            <Dropdown menu={{ items }} placement="bottomRight" arrow>
              <Button type='primary' >
                <Space>
                  {i18next.t('status_type')}
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </Col>
          <Col lg={{ span: 24 }}>
            {loading ? (
              <Skeleton active />
            ) : (
              <Table pagination={{
                position: "top" | "bottom",
                total: totalPagination,
                current: page,
                onChange: this.handlePaginationChange,
                defaultPageSize: pageSize,
                pageSizeOptions: [10, 20]
              }}
                dataSource={data} columns={columns} />
            )
            }
          </Col>
        </Row>
      </>
    )
  }
}
export default compose(withRouter(FuelPointRequest));