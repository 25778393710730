import { Button, Card, Col, Form, Row } from 'antd';
import i18next from "i18next";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/common/BreadCrumb';
import { MvBackButton, MvButton, MvCreateButton } from '../../components/common/Button/Button';
import { MvInput } from '../../components/common/Input/Input';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import CustomRoute from '../../routes/CustomRoute';
import withRouter from '../../network/withRouter';
import { compose } from '@reduxjs/toolkit';


export class FaqCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      on_creating_loading : false,
      items: [
        {
          title: i18next.t("home"),
        },
        {
          title: <Link to="/faqpage">{i18next.t("faq")}</Link>,
        },
        {
          title: i18next.t("faq.create"),
        },
      ],
    }
  }

  saveFaq = async (values) => {

    this.setState({
      on_creating_loading : true
    })

    const requestData = {
      "question": values.question,
      "answer": values.answer,
      "questionMM": values.questionMM,
      "answerMM": values.answerMM,
      "faqType": "NOTIFICATION",
      "status": values.status
    }

    try {
      const response = await ApiInstance(
        Api.faq, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
      if (response.status == 200) {
        this.props.navigate(CustomRoute.faq)
      }
    } catch (error) {
      console.log(error);

      this.setState({
        on_creating_loading : false
      })
    }
  };


  render() {
    const { items, on_creating_loading } = this.state;
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <BreadCrumb items={items} />
          </Col>
          <Col span={24}>
            <Card
              className="form"
              title={<h3>{i18next.t("faq.create")}</h3>}
              bordered={false}
              extra={
                <MvBackButton
                  path={'/faqpage'}
                  type="primary"
                  text={i18next.t("back")}
                ></MvBackButton>
              }
            >
              <Form
                layout="vertical"
                onFinish={this.saveFaq}
                ref={this.formRef}
              >
                <Row gutter={24}>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="Number"
                      label={i18next.t("number")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("number")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="question"
                      label={i18next.t("question")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("question")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="answer"
                      label={i18next.t("answer")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("answer")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="description"
                      label={i18next.t("description")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("description")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item style={{ textAlign: "end" }}>
                      <MvCreateButton onloading={on_creating_loading} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col >
        </Row>
      </>
    )
  }
}

export default compose(withRouter(FaqCreate))  