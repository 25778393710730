import { compose } from '@reduxjs/toolkit';
import { Card, Col, Form, Radio, Row } from 'antd';
import i18next from "i18next";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/common/BreadCrumb';
import { MvBackButton, MvUpdateButton } from '../../components/common/Button/Button';
import { MvDatePicker, MvInput, MvPasswordBox, MvTextarea } from '../../components/common/Input/Input';
import NRCInput from '../../components/common/nrc-input/NRCInput';
import { parseNRC, validatePhoneNumber } from '../../constants/validation';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import CustomRoute from '../../routes/CustomRoute';
import dayjs from 'dayjs';


export class DriverUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adminId: this.props.params.adminid,
      companyId: this.props.params.companyid,
      prevLoginId: '',
      prevAccountType: '',
      accountType: '',
      on_update_loading: false,
      items: [
        {
          title: "Home",
        },
        {
          title: <Link to="/driverpage">{i18next.t("driver")}</Link>,
        },
        {
          title: i18next.t("driver.update"),
        },
      ],
    }
    this.formRef = React.createRef();
  }


  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {

    const id = this.props.params.id

    try {
      const response = await ApiInstance(Api.driver, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, null, id);

      const initialNRCData = parseNRC(response.nrc);
      let driver;
      const dob = dayjs(response.dateOfBirth, 'YYYY-MM-DD');
      driver = { ...response };

      this.setState((prevState) => ({
        data: driver,
        prevLoginId: response.accounts[0].loginId,
        prevAccountType: response.accounts[0].accountType
      }));

      this.formRef.current.setFieldsValue({...driver, nrc: initialNRCData});
    } catch (error) { }
  };

  updateDriver = async (values) => {

    const { nrc } = values;

    const formattedNRC = `${nrc.nrcCode}/${nrc.township}(${nrc.nrcType})${nrc.nrcNumber}`;

    const { data, companyId, adminId, prevLoginId, prevAccountType } = this.state;

    this.setState({
      on_update_loading: true
    })

    const requestData = {
      "id": data.id,
      "uniqueId": values.uniqueId,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "emailAddress": values.emailAddress,
      "address": values.address,
      "nrc": formattedNRC,
      "gender": values.gender,
      "status": values.status,
      "dateOfBirth": values.dateOfBirth,
      "accounts":
        [{
          "loginId": values.phoneNumber,
          "password": values.password,
          "accountType": "PHONE"
        }],
      "company": {
        "id": data.company.id
      }
    }
    try {
      const response = await ApiInstance(Api.driver, HTTP_METHOD.PUT, MEDIA_TYPE.JSON, requestData, null);
      if (response.status == 200) {
        this.props.navigate(`${CustomRoute.admin}/${adminId}/${CustomRoute.company_manage}`)
      }
    } catch (error) {
      this.setState({
        on_update_loading: false
      })
    }
  };

  handleDateChange = (date, dateString) => {
    if (this.formRef?.current) {
      this.formRef.current.setFieldsValue({ dateOfBirth: dateString });
    }
  };

  accountTypeChange = (e) => {
    this.setState({
      accountType: e.target.value
    })
  };

  render() {
    const { items, on_update_loading, adminId, accountType, data } = this.state;
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <BreadCrumb items={items} />
          </Col>
          <Col span={24}>
            <Card
              className="form"
              title={<h3>{i18next.t("driver.update")}</h3>}
              bordered={false}
              extra={
                <MvBackButton
                  path={`${CustomRoute.admin}/${adminId}/${CustomRoute.company_manage}`}
                  type="primary"
                  text={i18next.t("back")}
                ></MvBackButton>
              }
            >
              <Form
                layout="vertical"
                onFinish={this.updateDriver}
                ref={this.formRef}
              >
                <Row gutter={24}>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="uniqueId"
                      label={i18next.t("driver_unique_id")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("driver_unique_id")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="firstName"
                      label={i18next.t("first_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("first_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="lastName"
                      label={i18next.t("last_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("last_name")}
                      />
                    </Form.Item>
                  </Col>
                  
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="phoneNumber"
                      label={i18next.t("phone_number")}
                      rules={[
                        {required: true, validator: validatePhoneNumber }
                      ]}
                    >
                      <MvInput size="large" placeholder="979xxxxxxx" />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="password"
                      label={i18next.t("password")}
                    >
                      <MvPasswordBox
                        size="large"
                        placeholder={i18next.t("password_placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="gender"
                      label={i18next.t("gender")}
                    >
                      <Radio.Group>
                        <Radio value="MALE">{i18next.t("male")}</Radio>
                        <Radio value="FEMALE">{i18next.t("female")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                      <NRCInput name="nrc" label="NRC" required  />
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="dateOfBirth"
                      label={i18next.t("date_of_birth")}
                    >
                      <MvDatePicker handleDateChange={this.handleDateChange} value={data?.dateOfBirth}/>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="status"
                      label={i18next.t("status")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <Radio.Group>
                        <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                        <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item name="address" label={i18next.t("address")}>
                      <MvTextarea
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item style={{ textAlign: "end" }}>
                      <MvUpdateButton loading={on_update_loading} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(withRouter(DriverUpdate));
