export const TitleLevel3 = (props) => {
    const { label } = props;
    return (
        <span style={{ fontSize: 27, fontWeight: '600' }}>{label}</span>
    )
}


export const CompanyTitle = (props) => {
    const { label } = props;
    return(
        <span style={{fontWeight:'bold', fontSize:'16px', color:'#16007a'}}>{label}</span>
    )
}

