import { compose } from '@reduxjs/toolkit';
import { Card, Col, Form, Radio, Row } from 'antd';
import i18next from "i18next";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/common/BreadCrumb';
import { MvBackButton, MvCreateButton } from '../../components/common/Button/Button';
import { MvDatePicker, MvInput, MvPasswordBox, MvTextarea } from '../../components/common/Input/Input';
import NRCInput from '../../components/common/nrc-input/NRCInput';
import { validatePhoneNumber } from '../../constants/validation';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import CustomRoute from '../../routes/CustomRoute';


export class DriverCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateOfBirth: null,
      adminId: this.props.params.adminid,
      companyId: this.props.params.companyid,
      on_creating_loading: false,
      accountType: '',
      items: [
        {
          title: "Home",
        },
        {
          title: <Link to={`${CustomRoute.admin}/${this.props.params.adminId}/${CustomRoute.company_manage}`}>{i18next.t("driver")}</Link>,
        },
        {
          title: i18next.t("driver.form"),
        },
      ],
    }
  }

  saveDriver = async (values) => {
    const { companyId, adminId, dateOfBirth } = this.state

    const { nrc } = values;

    const formattedNRC = `${nrc.nrcCode}/${nrc.township}(${nrc.nrcType})${nrc.nrcNumber}`;

    this.setState({
      on_creating_loading: true
    })

    const requestData = {
      "uniqueId": values.uniqueId,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "password": values.password,
      "emailAddress": values.emailAddress,
      "address": values.address,
      "nrc": formattedNRC,
      "gender": values.gender,
      "status": values.status,
      "dateOfBirth": dateOfBirth,
      "accounts": [
        {
          "loginId": values.phoneNumber,
          "password": values.password,
          "accountType": "PHONE"
        }
      ],
      "company": {
        "id": companyId
      }
    }

    try {
      const response = await ApiInstance(
        Api.driver, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
      if (response.status == 200) {
        this.props.navigate(`${CustomRoute.admin}/${adminId}/${CustomRoute.company_manage}`)
      }
    } catch (error) {
      console.log(error);

      this.setState({
        on_creating_loading: false
      })
    }
  };

  accountTypeChange = (e) => {
    this.setState({
      accountType: e.target.value
    })
  };

  handleDateChange = (date, dateString) => {
    this.setState({
      dateOfBirth:dateString
    })
  };

  render() {
    const { items, on_creating_loading, adminId, accountType } = this.state;
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <BreadCrumb items={items} />
          </Col>
          <Col span={24}>
            <Card
              className="form"
              title={<h3>{i18next.t("driver.form")}</h3>}
              bordered={false}
              extra={
                <MvBackButton
                  path={`${CustomRoute.admin}/${adminId}/${CustomRoute.company_manage}`}
                  type="primary"
                  text={i18next.t("back")}
                ></MvBackButton>
              }
            >
              <Form
                layout="vertical"
                onFinish={this.saveDriver}
              >
                <Row gutter={24}>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="uniqueId"
                      label={i18next.t("driver_unique_id")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("driver_unique_id")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="firstName"
                      label={i18next.t("first_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("first_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="lastName"
                      label={i18next.t("last_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("last_name")}
                      />
                    </Form.Item>
                  </Col>
                  
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="phoneNumber"
                      label={i18next.t("phone_number")}
                      rules={[
                        {required: true, validator: validatePhoneNumber }
                      ]}
                    >
                      <MvInput size="large" placeholder="979xxxxxxx" />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="password"
                      label={i18next.t("password")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvPasswordBox
                        size="large"
                        placeholder={i18next.t("password_placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="gender"
                      label={i18next.t("gender")}
                    >
                      <Radio.Group>
                        <Radio value="MALE">{i18next.t("male")}</Radio>
                        <Radio value="FEMALE">{i18next.t("female")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                      <NRCInput name="nrc" label="NRC" required />
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="dateOfBirth"
                      label={i18next.t("date_of_birth")}
                    >
                      <MvDatePicker handleDateChange={this.handleDateChange}/>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="status"
                      label={i18next.t("status")}
                    >
                      <Radio.Group>
                        <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                        <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item name="address" label={i18next.t("address")}>
                      <MvTextarea
                        autoSize={{ minRows: 3, maxRows: 5 }}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item style={{ textAlign: "end" }}>
                      <MvCreateButton loading={on_creating_loading} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(withRouter(DriverCreate));
