import { Breadcrumb, Button, Card, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import i18next, { t } from "i18next";
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { MvBackButton, MvButton, MvUpdateButton } from '../../components/common/Button/Button';
import { MvInput } from '../../components/common/Input/Input';
import BreadCrumb from '../../components/common/BreadCrumb';
import Api from '../../network/api';
import { compose } from '@reduxjs/toolkit';
import withRouter from '../../network/withRouter';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import CustomRoute from '../../routes/CustomRoute';


export class FaqUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      on_update_loading: false,
      items: [
        {
          title: i18next.t("home"),
        },
        {
          title: <Link to="/faqpage">{i18next.t("faq")}</Link>,
        },
        {
          title: i18next.t("faq.update"),
        },
      ],
    }
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {

    const id = this.props.params.id
    try {
      const response = await ApiInstance(Api.faq, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, null, id);

      let company;
      company = { ...response };

      this.setState((prevState) => ({
        data: company,
      }));

      this.formRef.current.setFieldsValue(company);
    } catch (error) { }
  };

  updateFaq = async (values) => {

    const { data } = this.state;


    this.setState({
      on_update_loading: true
    })

    const requestData = {
      "id": data.id,
      "question": values.question,
      "answer": values.answer,
      "questionMM": values.questionMM,
      "answerMM": values.answerMM,
      "faqType": "NOTIFICATION",
      "status": values.status
    }
    try {
      const response = await ApiInstance(Api.faq, HTTP_METHOD.PUT, MEDIA_TYPE.JSON, requestData, null);
      if (response.status == 200) {
        this.props.navigate(CustomRoute.faq)
      }
    } catch (error) {
      this.setState({
        on_update_loading: false
      })
    }
  };

  render() {
    const { items, on_update_loading } = this.state;
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <BreadCrumb items={items} />
          </Col>
          <Col span={24}>
            <Card
              className="form"
              title={<h3>{i18next.t("faq.update")}</h3>}
              bordered={false}
              extra={
                <MvBackButton
                  path={'/faqpage'}
                  type="primary"
                  text={i18next.t("back")}
                ></MvBackButton>
              }
            >
              <Form
                layout="vertical"
                onFinish={this.updateFaq}
                ref={this.formRef}
              >
                <Row gutter={24}>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="Number"
                      label={i18next.t("number")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("number")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="question"
                      label={i18next.t("question")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("question")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="answer"
                      label={i18next.t("answer")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("answer")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="description"
                      label={i18next.t("description")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("description")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 24 }}>
                    <Form.Item style={{ textAlign: "end" }}>
                      <MvUpdateButton loading={on_update_loading} />
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col >
        </Row>
      </>
    )
  }
}

export default compose(
  withRouter
)(FaqUpdate);