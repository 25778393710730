import { PlusCircleFilled, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Input } from "antd";
import i18next from "i18next";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { ErrorNotification } from "../../core-component";

export const MvButton = ({text,path,icon,...props}) => {
    return (
      <Link to={path}>
        <Button {...props} type="primary" icon={icon} shape="round">
        {text ? text : "Create"}
      </Button>
      </Link>
    );
  };


  export const MvBackButton = ({ path, icon, text, ...props }) => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(path); 
    };

    return (
        <Button onClick={handleButtonClick} {...props} type="primary" icon={icon} shape="round">
            {i18next.t("back")}
        </Button>
    );
};

export const AvatarButton = ({ onClick, icon, text }) => {
  const items = [
    {
      key: '1',
      label: (
        <a  onClick={onClick}>
          Logout
        </a>
      ),
    },
  ];

  return (
    <>
    <Dropdown
        menu={{
          items,
        }}
        placement="bottom"
        arrow
      >
        <a type="primary" icon={icon} className="avatar-button">
          <Avatar icon={<UserOutlined />} style={{ marginRight: 3 }} />
          {text}
      </a>
      </Dropdown>
      </>
      
  );
};

export const MvCreateButton = ({loading}) => {
  return (
    <>
      <Button type="primary" htmlType="submit" style={{borderRadius:'20px'}} loading={loading}>{i18next.t("create")}</Button>
    </>
  )
}

export const MvUpdateButton = ({loading}) => {
  return (
    <>
      <Button type="primary" htmlType="submit" style={{borderRadius:'20px'}} loading={loading}>{i18next.t("update")}</Button>
    </>
  )
}


