import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Modal, Row, Select, Space, Table } from 'antd';
import i18next from 'i18next';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MvButton } from '../../components/common/Button/Button';


const { Option } = Select;

const DriverCarAssignPage = (props) => {
  const [data, setData] = useState([
    { id: 1, driver_unique_id: 'D1', driver_name: 'John Doe', phone_number: '123-456-7890', vehicle: '' },
    { id: 2, driver_unique_id: 'D2', driver_name: 'Jane Smith', phone_number: '987-654-3210', vehicle: 'Car A' },
  ]);
  const [visible, setVisible] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [selectedCar, setSelectedCar] = useState('');
  const [carOptions] = useState([
    { id: 1, name: 'Car A' },
    { id: 2, name: 'Car B' }
  ]);

  const handleAssignClick = (record) => {
    setSelectedDriver(record);
    setSelectedCar(''); // Reset selected car when opening the modal
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
    setSelectedCar(''); // Reset selected car when closing the modal
  };

  const handleCarChange = (value) => {
    setSelectedCar(value);
  };

  const handleAssign = () => {
    setData(data.map(driver => {
      if (driver.id === selectedDriver.id) {
        return { ...driver, vehicle: selectedCar };
      }
      return driver;
    }));
    setVisible(false);
    setSelectedCar('');
  };

  const handleUnassign = () => {
    setData(data.map(driver => {
      if (driver.id === selectedDriver.id) {
        return { ...driver, vehicle: '' };
      }
      return driver;
    }));
    setVisible(false);
    setSelectedCar('');
  };

  const columns = [
    {
      title: 'Driver Unique ID',
      dataIndex: 'driver_unique_id',
      key: '1',
    },
    {
      title: 'Driver Name',
      dataIndex: 'driver_name',
      key: '2',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: '3',
    },
    {
      title: 'Vehicle',
      dataIndex: 'vehicle',
      key: '4',
      render: (text, record) => {
        if (text) {
          return <a onClick={() => handleAssignClick(record)}>{text}</a>;
        } else {
          return <a onClick={() => handleAssignClick(record)}>Unassign</a>;
        }
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: '5',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: '6',
      render: (user) => (
        <Space>
          <Link to="driverupdate">
            <Button size={"small"} type="primary" shape="circle">
              <EditOutlined />
            </Button>
          </Link>
          {/* <Link to={`${CustomRoute.user_delete}/${user.id}`}> */}
          <Button onClick={() => this.deleteUser(user.id)} size={"small"} type="primary" danger shape="circle">
            <DeleteOutlined />
          </Button>
          {/* </Link> */}
        </Space>
      )
    },
  ],
    items = [
      {
        title: "Home",
      },
      {
        title: <Link to="/companypage">{i18next.t("company")}</Link>,
      },
      {
        title: 'Driver',
      },
    ]

    useEffect(() => {
          const fetchData = async () => {
            try {
              // const response = await InvoiceApiPost("/", 3, props);
              // // const jsonData = await response.json();
              // setData(response);
              // setSelectedDriver(response)
              // console.log("data:", response)
            } catch (error) {
              console.error('Error fetching data:', error);
            }
          };
      
          fetchData(); // Call the fetchData function when the component mounts
      
          // Optionally, you can return a cleanup function if needed
          return () => {
            // Cleanup code here
          };
        }, []);

  return (
    <>
      <Row>
        <Col span={1}></Col>
        <Col span={22}>
          <Row style={{ marginBottom: "10px" }}>
            <Col span={8}>
              <Breadcrumb items={items} />
            </Col>
            <Col span={16} style={{ textAlign: "end" }}>
              <MvButton text={'Create Driver'} path={'drivercreate'}></MvButton>
            </Col>
          </Row>
          <Table dataSource={data} columns={columns} />
        </Col>
        <Col span={1}></Col>
      </Row>
      <Modal
        title="Assign Car"
        visible={visible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>Cancel</Button>,
          <Button
            key="assign"
            type="primary"
            onClick={selectedCar ? handleAssign : handleUnassign}
          >
            {selectedCar ? 'Assign' : 'Unassign'}
          </Button>
        ]}
      >
        <Select
          placeholder="Choose Car"
          style={{ width: '100%' }}
          onChange={handleCarChange}
          value={selectedCar}
        >
          {carOptions.map(car => (
            <Option key={car.id} value={car.name}>{car.name}</Option>
          ))}
        </Select>
      </Modal>
    </>
  );
};

export default DriverCarAssignPage;