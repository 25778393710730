import React, { useEffect } from 'react'
import Logo from "../assets/images/Logo.jpeg"
import { Input, Radio, notification } from 'antd';


const EdLogo = () => {
  return (
    <img style={{ height: '75px', width: '80px', marginLeft: '20px' }} src={Logo} alt='Logo' />
  )
}

export default EdLogo;

export const EdSearchBox = ({ onChangeProps, placeholder, onTextChange, ...props }) => {
  return (
    <Input
      style={{ backgroundColor: '#f2f2f2', height: 40 }}
      placeholder={placeholder}
      size="large"
      className="mv-search-box"
      allowClear={true}
      onKeyDown={onChangeProps}
      onChange={onTextChange}
      {...props}
    />
  );
};






