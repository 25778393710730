import { message, notification } from "antd";

export const notiError = (content) => {
            message.error({
                content: content,
                style: {
                    borderRadius: '8px', // Rounded corners
                    textAlign: 'end',
                    width: 'auto',
                    fontSize: '15px',
                    // padding: '25px 25px',
                },
            },
            3
        );
    }