import { Breadcrumb, Button, Card, Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import i18next, { t } from "i18next";
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { MvBackButton, MvButton, MvUpdateButton } from '../../components/common/Button/Button';
import { MvInput } from '../../components/common/Input/Input';
import BreadCrumb from '../../components/common/BreadCrumb';
import Api from '../../network/api';
import { compose } from '@reduxjs/toolkit';
import withRouter from '../../network/withRouter';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import { MvSelect } from '../../components/common/Select/Select';
import CustomRoute from '../../routes/CustomRoute';


export class NearByUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      on_update_loading : false,
      items: [
        {
          title: i18next.t("home"),
        },
        {
          title: <Link to="/nearbypage">{i18next.t("nearby")}</Link>,
        },
        {
          title: i18next.t("nearby.update"),
        },
      ],
    }
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    const id = this.props.params.id
    try {
      const response = await ApiInstance(Api.nearby, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, null, id);

      let company;
      company = { ...response };

      this.setState((prevState) => ({
        data: company,
      }));

      this.formRef.current.setFieldsValue(company);
    } catch (error) { }
  };

  updateFaq = async (values) => {

    const { data } = this.state;

    this.setState({
      on_update_loading : true
    })

    const requestData = {
      "id": data.id,
      "name": values.name,
      "latitude": values.latitude,
      "longitude": values.longitude,
      "address": values.address,
      "phone": values.phone,
      "nearbyType": "GAS_STATION",
      "status": values.status
    }

    try {
      const response = await ApiInstance(Api.nearby, HTTP_METHOD.PUT, MEDIA_TYPE.JSON, requestData, null);
      if (response.status == 200) {
        this.props.navigate(CustomRoute.nearby)
      }
    } catch (error) { }
  };

  render() {
    const { items, on_update_loading} = this.state;
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <BreadCrumb items={items} />
          </Col>
          <Col span={24}>
              <Card
                className="form"
                title={<h3>{i18next.t("nearby.update")}</h3>}
                bordered={false}
                extra={
                  <MvBackButton path={CustomRoute.nearby} />
                }
              >
                <Form
                  layout="vertical"
                  onFinish={this.updateFaq}
                  ref={this.formRef}
                >
                  <Row gutter={24}>
                    <Col lg={{ span: 12 }}>
                      <Form.Item
                        name="name"
                        label={i18next.t("name")}
                        rules={[{ required: true, message: "(*required)" }]}
                      >
                        <MvInput
                          size="large"
                          placeholder={i18next.t("name")}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 12 }}>
                      <Form.Item
                        name="latitude"
                        label={i18next.t("latitude")}
                        rules={[{ required: true, message: "(*required)" }]}
                      >
                        <MvInput
                          size="large"
                          placeholder={i18next.t("latitude")}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 12 }}>
                      <Form.Item
                        name="longitude"
                        label={i18next.t("longitude")}
                        rules={[{ required: true, message: "(*required)" }]}
                      >
                        <MvInput
                          size="large"
                          placeholder={i18next.t("longitude")}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 12 }}>
                      <Form.Item
                        name="address"
                        label={i18next.t("address")}
                        rules={[{ required: true, message: "(*required)" }]}
                      >
                        <MvInput
                          size="large"
                          placeholder={i18next.t("address")}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 12 }}>
                      <Form.Item
                        name="phone"
                        label={i18next.t("phone_number")}
                      >
                        <MvInput
                          size="large"
                          placeholder={i18next.t("phone_number")}
                        />
                      </Form.Item>
                    </Col>
                    <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="status"
                      label={i18next.t("status")}
                    >
                     <Radio.Group>
                        <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                        <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                    </Col>
                    <Col lg={{ span: 24 }}>
                      <Form.Item style={{ textAlign: "end" }}>
                        <MvUpdateButton loading={on_update_loading} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(
  withRouter
)(NearByUpdate);