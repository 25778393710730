import { EditOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Button, Col, DatePicker, Row, Skeleton, Table, Tag } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { MvButton } from '../../components/common/Button/Button';
import { EdSearchBox } from '../../components/core-component';
import { TitleLevel3 } from '../../components/general-component';
import { constant } from '../../constants/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';


class AdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteItem: "",
      data: [],
      pageNumber: '',
      page: constant.page,
      pageSize: constant.pageSize,
      totalPagination: 0,
      searchValue: [],
      data_filter_params: '',
      prevSearchTerm: '',
      columns: [
        {
          title: 'No.',
          key: 'index',
          render: (value, item, index) => <span>{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
          width: 5
        },
        {
          title: i18next.t('name'),
          dataIndex: 'fullName',
          key: '1',
        },
        {
          title: i18next.t('phone_number'),
          // dataIndex: ['accounts', 0, 'loginId'],
          dataIndex: ['phoneNumber'],
          key: '2',
        },
        {
          title: i18next.t('email'),
          dataIndex: 'emailAddress',
          key: '3',
        },
        {
          title: i18next.t('status'),
          dataIndex: 'status',
          key: '4',
          render: (status) => (
            <Tag color={status === 'ACTIVE' ? 'green' : 'red'}>
              {status}
            </Tag>
          )
        },
        {
          title: i18next.t('action'),
          dataIndex: '',
          key: 'action',
          width: 60,
          textAlign: 'center',
          render: (admin) => (
            <Link to={`adminupdate/${admin.id}`}>
              <Button size={"medium"} type="primary" shape="circle">
                <EditOutlined />
              </Button>
            </Link>
          )
        },
      ],
      items: [
        {
          title: i18next.t("home"),
        },
        {
          title: i18next.t('admin'),
        },
      ],
      loading: true
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (pageNumber) => {
    const { page, data_filter_params } = this.state;
    const params = {
      // ...data_filter_params,
      page: pageNumber ? pageNumber - 1 : page - 1,
      userType: "ADMIN"
    };
    await ApiInstance(Api.admin_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
      .then(response => {
        this.setState({
          data: response?.content,
          totalPagination: response?.totalElements,
          page: pageNumber ? pageNumber : page,
          loading: false
        })
      })
      .catch(error => {
        this.setState({
          loading: false
        })
      })
  }

  search = async () => {
    const { page, pageSize, searchTerm, data_filter_params, pageNumber } = this.state
    this.setState({
      isDataFetching: true
    })
    try {
      const params = {
        ...data_filter_params,
        userType: "ADMIN"
      };

      const response = await ApiInstance(Api.admin_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
      if (response && response.content) {
        this.setState(prevState => ({
          data: response.content,
          totalPagination: response.totalElements,
          page: page,
          prevSearchTerm: searchTerm
        }));
      }
    } catch (error) {
    }
    this.setState({
      isDataFetching: false
    })
  }

  handlePaginationChange = (pageNumber, pageSize) => {
    this.fetchData(pageNumber);
  };

  changeSearchName = (event, searchName) => {
    let { data_filter_params } = this.state;

    data_filter_params = {
      ...data_filter_params,
      [searchName]: event.target.value,
    }
    this.setState({
      data_filter_params: data_filter_params,
      searchTerm: event.target
    });

    if (event.target.value === '' && this.state.prevSearchTerm) {
      this.setState({
        data_filter_params: {
          ...data_filter_params,
          [searchName]: '',
        },
        prevSearchTerm: ''
      }, () =>
        this.search()
      );
    }
  }

  searchByDob = (date, dateString) => {
    console.log(date, dateString);
    let { data_filter_params } = this.state;



    if (date === null) {
      this.setState({
        data_filter_params: {
          ...data_filter_params,
          dateOfBirth: ''
        }
      }, () =>
        this.search()
      );

    }
    else {
      data_filter_params = {
        ...data_filter_params,
        dateOfBirth: dateString,
      }
      this.setState({
        data_filter_params: data_filter_params,
      });
    }
  }

  handleKeyDown = (e) => {
    this.search();
  };

  render() {
    const { loading, data, columns, totalPagination, pageSize, page } = this.state

    return (
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <TitleLevel3 label={"Admin"} />
        </Col>
        <Col span={16} style={{ textAlign: "end" }}>
          <MvButton path={'admincreate'} icon={<PlusCircleFilled />}></MvButton>
        </Col>
        <Col span={4}>
          <EdSearchBox
            size="middle"
            onChange={(values) =>
              this.changeSearchName(values, "fullName")}
            placeholder="SearchByName"
            onPressEnter={this.handleKeyDown}
          />
        </Col>
        <Col span={4}>
          <EdSearchBox
            size="middle"
            onChange={(values) =>
              this.changeSearchName(values, "phoneNumber")}
            placeholder="SearchByPhoneNumber"
            onPressEnter={this.handleKeyDown}
          />
        </Col>
        <Col span={4}>
        <DatePicker placeholder={i18next.t('select_date')} style={{ height: '40px', width: '100%' }} onChange={(date, dateString) => {
          this.searchByDob(date, dateString)}}/>
        </Col>
        <Col span={12}>
          <Button size="large" shape="round" onClick={() => this.search()} type="primary" >Search</Button>
        </Col>
        <Col span={24}>
          {
            loading ? (
              <Skeleton active />) : (
              <Table
                pagination={{
                  position: "top" | "bottom",
                  total: totalPagination,
                  current: page,
                  onChange: this.handlePaginationChange,
                  defaultPageSize: pageSize,
                }} dataSource={data} columns={columns} />
            )
          }
        </Col>
      </Row>
    )
  }
}
export default AdminPage;