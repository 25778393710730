import { compose } from "@reduxjs/toolkit";
import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";
import App from "../App";
import DriverPage from '../pages/Driver/DriverPage';
import DriverCreate from '../pages/Driver/DriverCreate';
import DriverUpdate from '../pages/Driver/DriverUpdate';
import VehiclePage from '../pages/Vehicle/VehiclePage';
import VehicleCreate from '../pages/Vehicle/VehicleCreate';
import VehicleUpdate from '../pages/Vehicle/VehicleUpdate';
import DriverCarAssignPage from '../pages/Drag&Drog/DriverCarAssignPage';
import AdminPage from '../pages/Admin/AdminPage';
import CompanyPage from '../pages/Company/CompanyPage';
import CompanyCreate from '../pages/Company/CompanyCreate';
import CompanyUpdate from '../pages/Company/CompanyUpdate';
import CoDriverCarAssign from '../pages/Company/CoDriverCarAssign';
import FuelStationPage from '../pages/FuelStation/FuelStationPage';
import FuelStationCreate from '../pages/FuelStation/FuelStationCreate';
import FuelStationUpdate from '../pages/FuelStation/FuelStationUpdate';
import AdminUpdate from '../pages/Admin/AdminUpdate';
import withRouter from ".././network/withRouter";
import FaqPage from '../pages/FAQ/FaqPage';
import FaqCreate from '../pages/FAQ/FaqCreate';
import FaqUpdate from '../pages/FAQ/FaqUpdate';
import NearByPage from '../pages/NearBy/NearByPage';
import NearByCreate from '../pages/NearBy/NearByCreate';
import NearByUpdate from '../pages/NearBy/NearByUpdate';
import Login from '../pages/Login/Login';
import { LOC_getAuthenticate } from "../core/custom-local-storage";
import  CustomRoute  from "./CustomRoute";
import FuelStaffPage from "../pages/Staff/fuelStaffPage";
import FuelStaffCreate from "../pages/Staff/fuelStaffCreate";
import FuelStaffUpdate from "../pages/Staff/fuelStaffUpdate";
import CompanyManage from "../pages/Company/manage";
import PageNotFound from "../pages/ErrorPages/pageNotFound";
import AdminCreate from "../pages/Admin/AdminCreate";
import FuelPointHistory from "../pages/FuelPointHistory/fuel-point-history";

class Router extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        console.log(LOC_getAuthenticate())
        return (
            <Routes>
                {
                    LOC_getAuthenticate() ? (
                        <>
                            <Route path='' element={<App />}>
                                <Route path='/' element={<AdminPage />} />
                                <Route path={CustomRoute.admin} element={<AdminPage />} />
                                <Route path={CustomRoute.admin_create} element={<AdminCreate />} />
                                <Route path={CustomRoute.admin_update_param} element={<AdminUpdate />} />


                                <Route path={CustomRoute.company} element={<CompanyPage />} />
                                <Route path={CustomRoute.company_create} element={<CompanyCreate />} />
                                <Route path={CustomRoute.company_update_param} element={<CompanyUpdate />} />
                                <Route path={CustomRoute.company_driver_car_assign} element={<CoDriverCarAssign />} />
                                
                                <Route path={CustomRoute.driver} element={<DriverPage />} />
                                <Route path={CustomRoute.driver_param} element={<DriverPage />} />
                                <Route path={CustomRoute.driver_create_param} element={<DriverCreate />} />
                                <Route path={CustomRoute.driver_update_param} element={<DriverUpdate />} />

                                <Route path={CustomRoute.company_manage_param} element={<CompanyManage />} />

                                <Route path={CustomRoute.vehicle} element={<VehiclePage />} />
                                <Route path={CustomRoute.vehicle_param} element={<VehiclePage />} />
                                <Route path={CustomRoute.vehicle_create_param} element={<VehicleCreate />} />
                                <Route path={CustomRoute.vehicle_update_param} element={<VehicleUpdate />} />

                                <Route path={CustomRoute.driver_car_assign} element={<DriverCarAssignPage />} />

                                <Route path={CustomRoute.fuel_station} element={<FuelStationPage />} />
                                <Route path={CustomRoute.fuel_station_param} element={<FuelStationPage />} />
                                <Route path={CustomRoute.fuel_station_create_param} element={<FuelStationCreate />} />
                                <Route path={CustomRoute.fuel_station_update} element={<FuelStationUpdate />} />
                                <Route path={CustomRoute.fuel_station_update_param} element={<FuelStationUpdate />} />

                                <Route path={CustomRoute.fuel_staff} element={<FuelStaffPage />} />
                                <Route path={CustomRoute.fuel_staff_param} element={<FuelStaffPage />} />
                                <Route path={CustomRoute.fuel_staff_create} element={<FuelStaffCreate />} />
                                <Route path={CustomRoute.fuel_staff_create_param} element={<FuelStaffCreate />} />
                                <Route path={CustomRoute.fuel_staff_update} element={<FuelStaffUpdate />} />
                                <Route path={CustomRoute.fuel_staff_update_param} element={<FuelStaffUpdate />} />

                                <Route path={CustomRoute.faq} element={<FaqPage />} />
                                <Route path={CustomRoute.faq_create} element={<FaqCreate />} />
                                <Route path={CustomRoute.faq_update_param} element={<FaqUpdate />} />

                                <Route path={CustomRoute.nearby} element={<NearByPage />} />
                                <Route path={CustomRoute.nearby_create} element={<NearByCreate />} />
                                <Route path={CustomRoute.nearby_update_param} element={<NearByUpdate />} />

                                <Route path={CustomRoute.fuel_point_history} element={<FuelPointHistory />} />

                                <Route path="/*" element={<PageNotFound />} />
                            </Route>
                        </>
                    ) :
                        <Route path="/*" element={<Login />} />
                }
                <Route path={CustomRoute.login} element={<Login />} />
            </Routes>
        )
    }
}
export default compose(withRouter)(Router);
