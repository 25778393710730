import { ConfigProvider, DatePicker, Input, InputNumber, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import { Option } from "antd/es/mentions";
import i18next from "i18next";
import React from 'react';
import './input.css';
import Theme from "../../../constants/Theme";

export const MvInput = ({ ...inputProps }) => {
  const { bgcolor } = inputProps;
  return (
    <ConfigProvider theme={{
      token: {
        colorBorder: '#fff',
        colorBgContainer: `${bgcolor ? bgcolor : Theme.colors.secondary}`,
        colorPrimaryHover: '#f5f5f5',
        colorPrimary: 'rgb(128, 128, 128)',
      },
      components: {
        Input: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        },
        InputNumber: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        }
      },
    }}>
      {
        inputProps.isPasswordInput ? <Input.Password size="large" {...inputProps} /> : inputProps.isNumberInput ? <InputNumber size="large" {...inputProps} /> :
          inputProps.isTextareaInput ? <Input.TextArea {...inputProps} /> : <Input {...inputProps} size="large" />
      }
    </ConfigProvider>
  )
}

export const MvPasswordBox = ({ ...inputProps }) => {
  const { bgcolor } = inputProps;
  return (
    <ConfigProvider theme={{
      token: {
        colorBorder: '#fff',
        colorBgContainer: `${bgcolor ? bgcolor : Theme.colors.secondary}`,
        colorPrimaryHover: '#f5f5f5',
        colorPrimary: 'rgb(128, 128, 128)',
      },
      components: {
        Input: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        },
        InputNumber: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        }
      },
    }}>
      <Input.Password size="large" {...inputProps} />
      </ConfigProvider>
  );
};

export const MvTextarea = ({ ...inputProps }) => {
  const { bgcolor } = inputProps;
  return (
    <ConfigProvider theme={{
      token: {
        colorBorder: '#fff',
        colorBgContainer: `${bgcolor ? bgcolor : Theme.colors.secondary}`,
        colorPrimaryHover: '#f5f5f5',
        colorPrimary: 'rgb(128, 128, 128)',
      },
      components: {
        Input: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        },
        InputNumber: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        }
      },
    }}>
    <Input.TextArea {...inputProps} />
    </ConfigProvider>
  );
};

export const MvSelectBox = ({ children, ...selectProps }) => {
  const { bgcolor, style } = selectProps;

  return (
    <ConfigProvider theme={{
      token: {
        colorBgContainer: `${bgcolor ? bgcolor : Theme.colors.secondary}`,
        colorBorder: '#fff',
        colorPrimary: 'rgb(128, 128, 128)',
        colorPrimaryHover: '#fff',
        // controlHeightLG: '40px',
      },
      components: {
        Select: {
          borderRadius: '9px',
          optionPadding: '4px 12px',
          optionSelectedBg: 'rgb(128, 128, 128)',
          optionSelectedColor: '#fff',
          optionHeight: 30
        }
      }
    }}>
      <Select {...selectProps} >{children}</Select>
    </ConfigProvider>
  );
};

export const MvDatePicker = ({ handleDateChange, value, ...datepickerProps }) => {
  const { bgcolor } = datepickerProps;
  
  return (
    <ConfigProvider theme={{
      token: {
        colorBgContainer: `${bgcolor ? bgcolor : Theme.colors.secondary}`,
        colorBorder: '#fff',
        colorPrimary: 'rgb(128, 128, 128)',
        colorPrimaryHover: '#fff',
        // controlHeightLG: '40px',
      },
      components: {
        Select: {
          borderRadius: '9px',
          optionPadding: '4px 12px',
          optionSelectedBg: 'rgb(128, 128, 128)',
          optionSelectedColor: '#fff',
          optionHeight: 30
        }
      }
    }}>
      <DatePicker
        // placeholder={i18next.t('select_date')}
        style={{ height: '40px', width: '100%' }}
        placeholder={value==null?i18next.t('select_date'):value}
        onChange={handleDateChange}
      />
    </ConfigProvider>
  )
}

export const MvLicensePlateInput = ({ value = '', onChange, type, ...inputProps }) => {
  const { bgcolor } = inputProps;
  const handleChange = (event) => {
    let inputValue = event.target.value;
    inputValue = inputValue.toUpperCase();
    if (inputValue.length > 2) {
      inputValue = inputValue.slice(0, 2) + '-' + inputValue.slice(2).replace(/[^0-9]/g, '');
    } else {
      inputValue = inputValue.replace(/[^A-Z0-9]/g, '');
    }
    onChange(inputValue);
  };

  return (
    <ConfigProvider theme={{
      token: {
        colorBorder: '#fff',
        colorBgContainer: `${bgcolor ? bgcolor : Theme.colors.secondary}`,
        colorPrimaryHover: '#f5f5f5',
        colorPrimary: 'rgb(128, 128, 128)',
      },
      components: {
        Input: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        },
        InputNumber: {
          activeShadow: '0 0 0 0.5px rgb(128,128,128)',
          borderRadius: 9,
          borderRadiusLG: 9,
        }
      },
    }}>
    <Input
      className="Mvinput"
      placeholder="LicensePlateNumber"
      type={type}
      size="large"
      maxLength={7}
      value={value}
      onChange={handleChange}
    />
     </ConfigProvider>
  );
};

