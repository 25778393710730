
export const SvgAdminFilled = (props) => {
    const { width, height, color } = props;
    return <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 500 500" width={width} height={height} fill={color}><circle cx="152" cy="184" r="72" /><path d="M234 296c-28.16-14.3-59.24-20-82-20-44.58 0-136 27.34-136 82v42h150v-16.07c0-19 8-38.05 22-53.93 11.17-12.68 26.81-24.45 46-34z" /><path d="M340 288c-52.07 0-156 32.16-156 96v48h312v-48c0-63.84-103.93-96-156-96z" /><circle cx="340" cy="168" r="88" /></svg>
}


export const SvgVehicle = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M23,11a1,1,0,0,1-1-1,8.008,8.008,0,0,0-8-8,1,1,0,0,1,0-2A10.011,10.011,0,0,1,24,10,1,1,0,0,1,23,11Zm-3-1a6,6,0,0,0-6-6,1,1,0,1,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0Zm2.183,12.164.91-1.049a3.1,3.1,0,0,0,0-4.377c-.031-.031-2.437-1.882-2.437-1.882a3.1,3.1,0,0,0-4.281.006l-1.906,1.606A12.784,12.784,0,0,1,7.537,9.524l1.6-1.9a3.1,3.1,0,0,0,.007-4.282S7.291.939,7.26.908A3.082,3.082,0,0,0,2.934.862l-1.15,1C-5.01,9.744,9.62,24.261,17.762,24A6.155,6.155,0,0,0,22.183,22.164Z" /></svg>
}
export const SvgCompany = (props) => {
    const { width, height, color = "gray" } = props;

    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 28 28"
            width={width || 20}
            height={height || 20}
            style={{ color }}
            fill={color}><path d="M14,13h2v2h-2v-2Zm4,2h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM14,7h2v-2h-2v2Zm4,0h2v-2h-2v2Zm-4,4h2v-2h-2v2Zm4,0h2v-2h-2v2ZM24,3V24H0V8.829c0-.801,.312-1.555,.879-2.122l2.707-2.707c1.289-1.29,3.539-1.29,4.828,0l1.586,1.586V3c0-1.654,1.346-3,3-3h8c1.654,0,3,1.346,3,3Zm-14,5.829c0-.267-.104-.519-.293-.708l-2.707-2.707c-.526-.526-1.474-.526-2,0l-2.707,2.707c-.189,.189-.293,.44-.293,.708v13.171H10V8.829ZM22,3c0-.551-.449-1-1-1H13c-.551,0-1,.449-1,1V22h10V3ZM5,15h2v-2h-2v2Zm0-4h2v-2h-2v2Zm0,8h2v-2h-2v2Z" /></svg>

    );
};

export const SvgDriver = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width={width} height={height} fill={color}><path d="M23,11a1,1,0,0,1-1-1,8.008,8.008,0,0,0-8-8,1,1,0,0,1,0-2A10.011,10.011,0,0,1,24,10,1,1,0,0,1,23,11Zm-3-1a6,6,0,0,0-6-6,1,1,0,1,0,0,2,4,4,0,0,1,4,4,1,1,0,0,0,2,0Zm2.183,12.164.91-1.049a3.1,3.1,0,0,0,0-4.377c-.031-.031-2.437-1.882-2.437-1.882a3.1,3.1,0,0,0-4.281.006l-1.906,1.606A12.784,12.784,0,0,1,7.537,9.524l1.6-1.9a3.1,3.1,0,0,0,.007-4.282S7.291.939,7.26.908A3.082,3.082,0,0,0,2.934.862l-1.15,1C-5.01,9.744,9.62,24.261,17.762,24A6.155,6.155,0,0,0,22.183,22.164Z" /></svg>
}

export const SvgFuelStation = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return (
        <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 25 25" width={width} height={height} fill={color}><path d="M23.414,4,19.707.293,18.293,1.707,20,3.414V6a2,2,0,0,0,2,2V18a1,1,0,0,1-2,0V17a3,3,0,0,0-3-3H16V3a3,3,0,0,0-3-3H3A3,3,0,0,0,0,3V24H16V16h1a1,1,0,0,1,1,1v1a3,3,0,0,0,6,0V5.414A2.011,2.011,0,0,0,23.414,4ZM3,2H13a1,1,0,0,1,1,1V9H2V3A1,1,0,0,1,3,2ZM2,22V11H14V22Z" /></svg>

    );
}

export const SvgFaq = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 30 30" width={width} height={height} fill={color}><path d="M9,11c-.552,0-1-.447-1-1,0-1.308,1.038-1.879,1.481-2.123,.29-.159,.595-.535,.502-1.066-.069-.392-.402-.725-.793-.793-.306-.056-.602,.022-.832,.216-.228,.19-.358,.47-.358,.767,0,.553-.448,1-1,1s-1-.447-1-1c0-.889,.391-1.727,1.072-2.299,.681-.572,1.577-.814,2.463-.653,1.209,.211,2.204,1.205,2.417,2.417,.223,1.272-.382,2.543-1.506,3.164-.447,.246-.447,.318-.447,.371,0,.553-.448,1-1,1Zm0,1c-.69,0-1.25,.56-1.25,1.25s.56,1.25,1.25,1.25,1.25-.56,1.25-1.25-.56-1.25-1.25-1.25Zm10.996-2.92c-.006,.769-.091,1.518-.248,2.242,1.371,1.101,2.252,2.787,2.252,4.678v5c0,.552-.448,1-1,1h-5c-1.891,0-3.577-.881-4.678-2.252-.724,.156-1.473,.242-2.242,.248,1.385,2.389,3.965,4.004,6.92,4.004h5c1.657,0,3-1.343,3-3v-5c0-2.955-1.615-5.535-4.004-6.92Zm-2.019,.571c.185-2.613-.768-5.17-2.613-7.016S10.964-.167,8.349,.023C3.823,.343,0,4.589,0,9.296v5.038c0,2.021,1.642,3.666,3.661,3.666h4.477c5.187,0,9.509-3.667,9.839-8.349Zm-4.027-5.601c1.436,1.435,2.176,3.425,2.033,5.46-.253,3.578-3.772,6.489-7.845,6.489H3.661c-.916,0-1.661-.747-1.661-1.666v-5.038c0-3.696,2.972-7.029,6.49-7.278,.167-.012,.333-.018,.499-.018,1.858,0,3.644,.732,4.961,2.051Z" /></svg>
}

export const SvgNearby = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Isolation_Mode" data-name="Isolation Mode" viewBox="0 0 28 26" width={width} height={color} fill={color}><path d="M12,.021A10.077,10.077,0,0,0,1.935,10.087c0,5.274,8.166,12.329,9.1,13.117l.967.817.967-.817c.932-.788,9.1-7.843,9.1-13.117A10.077,10.077,0,0,0,12,.021Zm0,20.043c-3.065-2.792-7.067-7.328-7.067-9.977a7.067,7.067,0,1,1,14.134,0C19.067,12.731,15.064,17.269,12,20.064Z"/><circle cx="12" cy="10.002" r="2.997"/></svg>
}

export const SvgPointHistory = (props) => {
    const { width, height, color } = props;
    if (width == null || width == undefined)
        width = 20
    if (height == null || height == undefined)
        height = 20
    return <svg xmlns="http://www.w3.org/2000/svg" id="Bold" viewBox="0 0 33 25" width={width} height={color} fill={color}><path d="M12,0A12.034,12.034,0,0,0,4.04,3.04L2.707,1.707A1,1,0,0,0,1,2.414V7A1,1,0,0,0,2,8H6.586a1,1,0,0,0,.707-1.707L6.158,5.158A9,9,0,0,1,21,12.26,9,9,0,0,1,3.1,13.316,1.51,1.51,0,0,0,1.613,12h0A1.489,1.489,0,0,0,.115,13.663,12.018,12.018,0,0,0,12.474,23.991,12.114,12.114,0,0,0,23.991,12.474,12.013,12.013,0,0,0,12,0Z" /><path d="M11.5,7h0A1.5,1.5,0,0,0,10,8.5v4.293a2,2,0,0,0,.586,1.414L12.379,16A1.5,1.5,0,0,0,14.5,13.879l-1.5-1.5V8.5A1.5,1.5,0,0,0,11.5,7Z" /></svg>
}
