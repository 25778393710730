import { compose } from '@reduxjs/toolkit';
import { Card, Col, Form, Radio, Row } from 'antd';
import i18next from "i18next";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/common/BreadCrumb';
import { MvBackButton, MvUpdateButton } from '../../components/common/Button/Button';
import { MvDatePicker, MvInput, MvPasswordBox } from '../../components/common/Input/Input';
import NRCInput from '../../components/common/nrc-input/NRCInput';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import CustomRoute from '../../routes/CustomRoute';
import dayjs from 'dayjs';
import { parseNRC } from '../../constants/validation';


class AdminUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      on_update_loading: false,
      items: [
        {
          title: <Link to={CustomRoute.admin}>{i18next.t("home")}</Link>,
        },
        {
          title: <Link to={CustomRoute.admin}>{i18next.t("admin")}</Link>,
        },
        {
          title: i18next.t("admin.update"),
        },
      ],
      initialNrcValue: '',

      loading: true,
      accountId: null
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();

  }

  fetchData = async () => {
    console.log("fetch")
    const id = this.props.params.id
    try {
      const response = await ApiInstance(Api.admin, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, null, id);
      const initialNRCData = parseNRC(response.nrc);

      console.log("ini1", response.nrc)
      // console.log("ini", initialNRCData)

      let admin;
      const dob = dayjs(response.dateOfBirth, 'YYYY-MM-DD');
      admin = { ...response };

      this.setState((prevState) => ({
        data: admin,
        accountId: admin.accounts[0].id
      }));

      console.log("admin:", admin)

      this.formRef.current.setFieldsValue({...admin , nrc: initialNRCData});
    } catch (error) { }
  };

  updateAdmin = async (values) => {

    const { data, accountId } = this.state;

    const { nrc } = values;

    const formattedNRC = `${nrc.nrcCode}/${nrc.township}(${nrc.nrcType})${nrc.nrcNumber}`;

    this.setState({
      on_update_loading: true,
    })

    console.log("values:", values)

    const requestData = {
      "id": data.id,
      "uniqueId": values.uniqueId,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "dateOfBirth": values.dateOfBirth,
      "emailAddress": values.emailAddress,
      "phoneNumber": values.accounts[0].loginId,
      "address": values.address,
      "nrc": formattedNRC,
      "gender": values.gender,
      "status": values.status,
      "accounts": [
        {
          "id": data.accounts[0].id,
          "loginId": values.accounts[0].loginId,
          "password": values.password,
          "accountType": "PHONE"
        }
      ]
    }

    try {
      const response = await ApiInstance(Api.admin, HTTP_METHOD.PUT, MEDIA_TYPE.JSON, requestData, null);
      if (response.status == 200) {
        this.props.navigate(CustomRoute.admin)
      }
    } catch (error) {
      this.setState({
        on_update_loading: false
      })
    }
  };

  handleNrcChange = (formattedNrc) => {
    this.formRef.current.setFieldsValue({ nrc: formattedNrc });
  };

  handleDateChange = (date, dateString) => {
    if (this.formRef?.current) {
      this.formRef.current.setFieldsValue({ dateOfBirth: dateString });
    }
  };
  
  render() {
    const { items, on_update_loading, data } = this.state;
    console.log("data:", data);
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <BreadCrumb items={items} />
          </Col>
          <Col span={24}>
            <Card
              className="form"
              title={<h3>{i18next.t("admin.update")}</h3>}
              bordered={false}
              extra={
                <MvBackButton
                  path={CustomRoute.admin}
                  type="primary"
                  text={i18next.t("back")}
                ></MvBackButton>}
            >
              <Form
                layout="vertical"
                onFinish={this.updateAdmin}
                ref={this.formRef}
              >
                <Row gutter={24}>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="uniqueId"
                      label={i18next.t("unique_id")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("unique_id")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="firstName"
                      label={i18next.t("first_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("first_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="lastName"
                      label={i18next.t("last_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("last_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="emailAddress"
                      label={i18next.t("email")}
                      rules={[{ required: true, message: "(*required)" },
                        {
                          type: 'email',
                          message: 'The input is not a valid email!',
                        },]}
                    >
                      <MvInput size="large" placeholder={i18next.t("email")} />
                    </Form.Item>
                  </Col>

                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name={['accounts', 0, 'loginId']}
                      label={i18next.t("phone_number")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput size="large" placeholder="979xxxxxxx" />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name='password'
                      label={i18next.t("password")}
                    >
                      <MvPasswordBox
                        size="large"
                        placeholder={i18next.t("password_placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="dateOfBirth"
                      label={i18next.t("date_of_birth")}
                    >
                      <MvDatePicker handleDateChange={this.handleDateChange} value={data?.dateOfBirth}/>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                      <NRCInput name="nrc" label="NRC" required  />
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="gender"
                      label={i18next.t("gender")}
                    >
                      <Radio.Group>
                        <Radio value="MALE">{i18next.t("male")}</Radio>
                        <Radio value="FEMALE">{i18next.t("female")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      className='status'
                      name="status"
                      label={i18next.t("status")}
                    >
                      <Radio.Group>
                        <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                        <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item style={{ textAlign: "end" }}>
                  <MvUpdateButton loading={on_update_loading} />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(
  withRouter
)(AdminUpdate);