import { Col, Layout, Menu, Row } from 'antd';
import LocaleProvider from 'antd/es/locale';
import zhCN from 'antd/locale/zh_CN';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { SvgAdminFilled, SvgCompany, SvgFaq, SvgNearby, SvgPointHistory } from './assets/SvgIcons';
import { theme } from './assets/theme';
import { AvatarButton } from './components/common/Button/Button';
import EdLogo from './components/core-component';
import CustomRoute from './routes/CustomRoute';
import { logoutAndResetReduxState } from './state/redux/authTokenSlice';
import { useDispatch } from 'react-redux';
import enUS from 'antd/es/calendar/locale/en_US';


const { Header, Content, Sider } = Layout;
const languages = [
  {
    code: "fr",
    name: "Français",
    country_code: "fr",
  },
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    dir: "rtl",
    country_code: "sa",
  },
];


const App = () => {
  const {
    token: { colorBgContainer, colorBgContent },
    components: { Header: headerTheme, Content: contentTheme },
  } = theme;
  const currentLanguageCode = Cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  const location = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const items = [
    {
      key: "1",
      label: (
        <a
          href="#"
          onClick={() => {
            i18next.changeLanguage("en");
          }}
        >
          English
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          href="#"
          onClick={() => {
            i18next.changeLanguage("fr");
          }}
        >
          Français
        </a>
      ),
    },
  ];

  const handleLogout = () => {
    dispatch(logoutAndResetReduxState());
    localStorage.clear();
    navigate("/")
  }

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
    localStorage.setItem("current-route", JSON.stringify(location.pathname));
    // console.log('Route changed to:', location.pathname);
  }, [currentLanguage, t, location.pathname]);


  return (
    <LocaleProvider locale={enUS}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Layout
        style={{
          transform: 'scale(0.9)',
          transformOrigin: 'top left',
          width: '111.11vw',
          height: '111.11vh',
          position: 'absolute',
          top: '0',
          left: '0',
        }}>
        <Header
          theme='light'
          style={{
            background: headerTheme.colorBgContainer,
            color: headerTheme.colorPrimary,
            height: "75px",
            display: "flex",
            alignItems: "center",
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            paddingLeft: 10,
            paddingRight: 10,
            zIndex: 2,
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
          }}
        >
          <Col span={4} style={{ paddingTop: '25px' }}>
            <EdLogo />
          </Col>
          <Col span={16}></Col>
          <Col span={4} style={{ textAlign: 'end', paddingRight: '30px' }}>
            <AvatarButton onClick={handleLogout} />
          </Col>
        </Header>
        <Sider
          width={250}
          theme='light'
          style={{
            position: 'fixed',
            height: '100vh',
            left: 0,
            top: 55,
            bottom: 0,
            paddingTop: 20,
          }}
        >
          <Menu
            mode="inline"
            style={{
              height: '100vh',
              borderRight: 0,
            }}
          >
            <Menu.Item key="1">
              <Link to={CustomRoute.admin}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SvgAdminFilled width={24} height={20} color="gray"></SvgAdminFilled>
                  <span className="sidebar-text">{t('admin')}</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="2">
              <Link to={CustomRoute.company}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SvgCompany width={23} height={23} color="gray"></SvgCompany>
                  <span className="sidebar-text">{t('company')}</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="3">
              <Link to={CustomRoute.faq}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <SvgFaq width={25} height={25} color="gray" />
                  <span className="sidebar-text">{t('FAQ')}</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="4">
              <Link to={CustomRoute.nearby}>
                <div style={{ display: "flex", alignItems: "center", padding: '' }}>
                  <SvgNearby width={25} height={24} color="gray" />
                  <span className="sidebar-text">{t('NearBy')}</span>
                </div>
              </Link>
            </Menu.Item>
            <Menu.Item key="5">
              <Link to={CustomRoute.fuel_point_history}>
                <div style={{ display: "flex", alignItems: "center", }}>
                  <SvgPointHistory width={25} height={24} color="gray" />
                  <span className="sidebar-text">{t('fuel_point_history')}</span>
                </div>
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout
          style={{
            padding: '0 24px 24px',
          }}
        >
          <Content
            style={{
              position: 'fixed',
              padding: 24,
              margin: 0,
              minHeight: 500,
              background: colorBgContent,
              top: 45,
              left: 250,
              right: 0,
              bottom: 0,
              overflow: 'scroll',
              padding: "50px 30px 24px 30px",
            }}
          >
            <Outlet />

          </Content>
        </Layout>
      </Layout>
    </LocaleProvider>
  );
};
export default App;
