import { EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Button, Col, DatePicker, Row, Skeleton, Space, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Api from "../../network/api";
// import CustomRoute from "../../routes/customRoute";
import i18next from "i18next";
import { MvBackButton, MvButton } from "../../components/common/Button/Button";
import { EdSearchBox } from "../../components/core-component";
import { CompanyTitle, TitleLevel3 } from "../../components/general-component";
import { constant } from "../../constants/constant";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiInstance } from "../../network/network-manager";
import withRouter from "../../network/withRouter";
import CustomRoute from "../../routes/CustomRoute";

class FuelStaffPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adminId: this.props.params.adminid,
      data: [],
      isDataFetching: false,
      page: constant.page,
      pageSize: constant.pageSize,
      pageNumber: 0,
      totalPagination : 0,
      searchValue: [],
      data_filter_params: '',
      prevSearchTerm: '',
      searchValue: [],
      openModal: false,
      openCreateModal: false,
      openUpdateModal: false,
      activeStatus: true,
      updateForm: {},
      stationName: '',
    }
  }

  componentDidMount() {
    this.fetchData()
    this.fetchStation()
  }

  fetchData = async (pageNumber, value) => {
    const { page, searchValue, pageSize } = this.state
    this.setState({
      isDataFetching: true
    })
    try {
      const params = {
        fuelStationId: this.props.params.stationid,
        page: pageNumber ? pageNumber - 1 : page - 1,
      };
      const response = await ApiInstance(Api.fuel_staff_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
      if (response && response.content) {
        this.setState(prevState => ({
          data: response.content,
          totalPagination: response.totalElements,
        }));
      }
    } catch (error) {
    }
    this.setState({
      isDataFetching: false,
      searchValue: value ? value : searchValue
    })
  }

  search = async () => {
    const { page, pageNumber, searchTerm, data_filter_params } = this.state
    this.setState({
      isDataFetching: true
    })
    try {
      const params = {
        ...data_filter_params,
        fuelStationId: this.props.params.stationid,
        page: pageNumber ? pageNumber - 1 : page - 1,
      };

      const response = await ApiInstance(Api.fuel_staff_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
      if (response && response.content) {
        this.setState(prevState => ({
          data: response.content,
          totalPagination: response.totalElements,
          page: page,
          prevSearchTerm: searchTerm
        }));
      }
    } catch (error) {
    }
    this.setState({
      isDataFetching: false
    })
  }

  handlePaginationChange = (pageNumber, pageSize) => {
    this.fetchData(pageNumber);
  };

  changeSearchName = (event, searchName) => {
    let { data_filter_params } = this.state;

    data_filter_params = {
      ...data_filter_params,
      [searchName]: event.target.value,
    }
    this.setState({
      data_filter_params: data_filter_params,
      searchTerm: event.target
    });

    if (event.target.value === '' && this.state.prevSearchTerm) {
      this.setState({
        data_filter_params: {
          ...data_filter_params,
          [searchName]: '',
        },
        prevSearchTerm: ''
      }, () =>
        this.search()
      );
    }
  }
  changeDateOfBirth = (date, dateString) => {
    console.log(date, dateString);
    let { data_filter_params } = this.state;

    data_filter_params = {
      ...data_filter_params,
      dateOfBirth: dateString,
    }
    this.setState({
      data_filter_params: data_filter_params,
    });
  }

  handleKeyDown = (e) => {
    this.search();
  };

  handlePaginationChange = (pageNumber, pageSize) => {
    this.fetchData(pageNumber);
  };

  fetchStation = async () => {
    const id = this.props.params.stationid
    try {
      const response = await ApiInstance(Api.fuel_staff, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, null, id);

      let station;
      station = { ...response };


      this.setState((prevState) => ({
        stationName: station.stationName,
      }));

    } catch (error) { }
  };

  

  render() {
    const { data, stationName, page, pageSize, isDataFetching, adminId, totalPagination } = this.state
    const getRowClassName = (record, index) => {
      return index % 2 === 0 ? 'blue-row' : 'black-row';
    };
    const columns = [
      {
        title: 'No.',
        key: 'index',
        render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
        width: 5
      },
      {
        title: i18next.t('unique_id'),
        dataIndex: 'uniqueId',
        key: 'uniqueId',
      },
      {
        title: i18next.t('login_id'),
        dataIndex : 'accounts',
        key : 'loginId',
        render: (account) => account[0].loginId
      },
      {
        title: i18next.t('name'),
        dataIndex: 'fullName',
        key: 'name',
      },
      {
        title: i18next.t('email'),
        dataIndex: 'emailAddress',
        key: 'emailAddress',
      },
      {
        title: i18next.t('address'),
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: i18next.t('user_type'),
        dataIndex: 'userType',
        key: 'userType',
        render: (type) => (
          <>{type === 'FUEL_STAFF' ? "Fuel Staff" : "Fuel Manager"}</>
        )
      }, {
        title: 'Action',
        key: 'action',
        dataIndex: '',
        textAlign: 'center',
        width: 60,
        render: (data) => (
          <Space>
            <Link to={`${CustomRoute.fuel_staff_update}/${data.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
          </Space>
        ),
      },
    ]
    return (
      <>
        <CompanyTitle label={stationName} />
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <TitleLevel3 label={i18next.t("fuel_staff")} />
          </Col>
          <Col span={16} style={{ textAlign: "end" }}>
            <MvBackButton path={`${CustomRoute.admin}/${adminId}/${CustomRoute.company_manage}`} />
          </Col>
          <Col span={4}><EdSearchBox
          size="middle"
           onChange={(values) =>
            this.changeSearchName(values, "fullName")}
            placeholder="SearchByName"
            onPressEnter={this.handleKeyDown}
          /></Col>
          <Col span={4}><EdSearchBox
          size="middle"
           onChange={(values) => this.changeSearchName(values, "phoneNumber")}
            placeholder="SearchByPhoneNumber"
            onPressEnter={this.handleKeyDown}
          /></Col>
          <Col span={4}>
            <DatePicker placeholder={i18next.t('select_date')} style={{ height: '40px', width: '100%' }} onChange={(date, dateString) => {
              this.changeDateOfBirth(date, dateString)}}/>
          </Col>
          <Col span={8}>
            <Button size="large" shape="round" onClick={() => this.search()} type="primary" >Search</Button>
          </Col>
          <Col span={4} style={{ textAlign: 'end' }}><MvButton path={CustomRoute.fuel_staff_create} icon={<PlusCircleFilled />}></MvButton></Col>
          <Col span={24}>
            {
              isDataFetching ?
                <Skeleton active />
                :
                <Table
                pagination={{
                  position: "top" | "bottom",
                  total: totalPagination,
                  current: page,
                  onChange: this.handlePaginationChange,
                  defaultPageSize: pageSize,
                }}
    
                  style={{ borderRadius: 10 }}
                  columns={columns} dataSource={data}
                  rowClassName={getRowClassName} />
            }
          </Col>
        </Row>
      </>
    );
  }
}


export default compose(withRouter)(FuelStaffPage)