import { CiCircleFilled, DeleteOutlined, EditOutlined, PlusCircleFilled } from "@ant-design/icons";
import { compose } from "@reduxjs/toolkit";
import { Breadcrumb, Button, Col, Input, Modal, Row, Skeleton, Space, Table, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import Api from "../../network/api";
// import CustomRoute from "../../routes/customRoute";
import withRouter from "../../network/withRouter";
import { TitleLevel3 } from "../../components/general-component";
import { ApiInstance } from "../../network/network-manager";
import { MvButton } from "../../components/common/Button/Button";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import i18next from "i18next";
import { EdSearchBox } from "../../components/core-component";
import CustomRoute from "../../routes/CustomRoute";
import { constant } from "../../constants/constant";

class NearByPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isDataFetching: false,
            page: constant.page,
            pageSize: constant.pageSize,
            pageNumber: 0,
            totalPagination: 0,
            searchValue: [],
            data_filter_params: '',
            prevSearchTerm: '',
            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            updateForm: {},
            searchTerm: '',
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async (pageNumber, value) => {
        const { page, searchValue, data_filter_params } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            const params = {
                ...data_filter_params,
                page: pageNumber ? pageNumber - 1 : page - 1,
            };
            const response = await ApiInstance(Api.nearby_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page,
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false,
            searchValue: value ? value : searchValue
        })
    }

    search = async () => {
        const { page, pageSize, searchTerm, data_filter_params } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            const params = {
                ...data_filter_params,
            };

            const response = await ApiInstance(Api.nearby_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: page,
                    prevSearchTerm: searchTerm
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    changeSearchName = (event, searchName) => {
        let { data_filter_params } = this.state;

        data_filter_params = {
            ...data_filter_params,
            [searchName]: event.target.value,
        }
        this.setState({
            data_filter_params: data_filter_params,
            searchTerm: event.target
        });

        if (event.target.value === '' && this.state.prevSearchTerm) {
            this.setState({
                data_filter_params: {
                  ...data_filter_params,
                  [searchName]: '',
                },
                prevSearchTerm: ''
              }, () =>
                this.search()
              );
        }
    }

    handleKeyDown = (e) => {
        this.search();
    };

    render() {
        const { data, totalPagination, page, pageSize, openModal, isDataFetching } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: i18next.t('name'),
                dataIndex: 'name',
                key: 'name',
            }, {
                title: i18next.t('address'),
                dataIndex: 'address',
                key: 'address',
            }, {
                title: i18next.t('phone'),
                dataIndex: 'phone',
                key: 'phone',
            }, {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (nearby) => (
                    <Space>
                        <Link to={`${CustomRoute.nearby_update}/${nearby.id}`}><Button size={'middle'} type="primary" shape="circle"><EditOutlined /></Button></Link>
                    </Space>
                ),
            },
        ]
        return (
            <>
                <Modal
                    title="Delete"
                    open={openModal}
                    onOk={() => this.deleteFAQConfirm()}
                    onCancel={() => this.setState({ openModal: false })}
                    okText="Confirm"
                    cancelText="Cancel"
                >
                    <p>Are you sure to delete?</p>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <TitleLevel3 label={i18next.t("nearby")} />
                    </Col>
                    <Col span={16} style={{ textAlign: "end" }}>
                        <MvButton path={'nearbycreate'} icon={<PlusCircleFilled />}></MvButton>
                    </Col>
                    <Col span={4}><EdSearchBox
                        size="middle"
                        onChange={(values) =>
                            this.changeSearchName(values, "name")}
                        placeholder="SearchByName"
                        onPressEnter={this.handleKeyDown}
                    /></Col>
                    <Col span={4}><EdSearchBox
                        size="middle"
                        onChange={(values) => this.changeSearchName(values, "phone")}
                        placeholder="SearchByPhoneNumber"
                        onPressEnter={this.handleKeyDown}
                    /></Col>
                    <Col span={16}>
                        <Button size="large" shape="round" onClick={() => this.search()} type="primary" >Search</Button>
                    </Col>
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={{
                                        position: 'top' | 'bottom',
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize
                                    }}
                                    columns={columns} dataSource={data}
                                    rowClassName={getRowClassName} />
                        }
                    </Col>
                </Row>
            </>
        );
    }
}


export default compose(withRouter)(NearByPage)