import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { createTransform } from "redux-persist";
import { encryptTransform } from 'redux-persist-transform-encrypt';
import authTokenSlice from "./authTokenSlice";
import companySlice from "./companySlice";

const secretKey = '**&&%%$$11';

const encryptor = encryptTransform({
  secretKey: secretKey,
  onError: function (error) {
    // Handle any encryption errors here.
  },
});

const obfuscateTransform = createTransform(
  (inboundState) => {
    // Obfuscate the data by base64 encoding
    return btoa(JSON.stringify(inboundState));
  },
  (outboundState) => {
    // Decode the obfuscated data
    return JSON.parse(atob(outboundState));
  }
);

const persistConfig = {
  key: "revirx-admin",
  // storage: storage,
  transforms: [encryptor, obfuscateTransform],
};

const rootReducer = combineReducers({ 
   auth: authTokenSlice,
 })

export const store = configureStore({
    reducer: rootReducer
})