import { Select } from "antd";
import i18next from "i18next";
import "./Select.css";

const { Option } = Select;

export const MvSelect = ({ options, ...props }) => {
    return (
        <div className="mv-select">
            <Select
                {...props}
                size="large"
                style={{
                    height: "40px",
                    border: "none",
                }}
                placeholder={i18next.t("Select Status")}
            >
                {options && options.map((option) => (
                    <Option key={option} value={option}>
                        {option}
                    </Option>
                ))}
            </Select>
        </div>
    );
};