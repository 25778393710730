import React from 'react'
import { Breadcrumb, Button, Card, Col, DatePicker, Form, Input, Radio, Row, Select } from 'antd';
import i18next from "i18next";
import { MvBackButton, MvButton, MvCreateButton } from '../../components/common/Button/Button';
import { MvInput, MvPasswordBox, MvTextarea } from '../../components/common/Input/Input';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/common/BreadCrumb';
import { MvSelect } from '../../components/common/Select/Select';
import Api from '../../network/api';
import { ApiInstance } from '../../network/network-manager';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import CustomRoute from '../../routes/CustomRoute';
import { compose } from '@reduxjs/toolkit';
import withRouter from '../../network/withRouter';
import { validatePhoneNumber } from '../../constants/validation';

class FuelStationCreate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            adminId: this.props.params.adminid,
            companyId: this.props.params.companyid,
            on_creating_loading: false,
            items: [
                {
                    title: i18next.t("home"),
                },
                {
                    title: <Link to={`${CustomRoute.admin}/${this.props.params.adminId}/${CustomRoute.company_manage}`}>{i18next.t("fuel_station")}</Link>,
                },
                {
                    title: i18next.t("fuel_station.form")
                },
            ]
        }
    }

    saveFuelStation = async (values) => {

        const { companyId, adminId } = this.state

        this.setState({
            on_creating_loading: true
        })

        const requestData = {

            "stationName": values.stationName,
            "phoneNumber": values.phoneNumber,
            "address": values.address,
            "latitude": values.longitude,
            "longitude": values.latitude,
            "ownerName": values.ownerName,
            "status": values.status,
            "company": {
                "id": companyId
            }
        }

        try {
            const response = await ApiInstance(
                Api.fuel_station, HTTP_METHOD.POST, MEDIA_TYPE.JSON, requestData);
            if (response.status == 200) {
                this.props.navigate(`${CustomRoute.admin}/${adminId}/${CustomRoute.company_manage}`)
            }
        } catch (error) {
            console.log(error);

            this.setState({
                on_creating_loading: false
            })
        }
    };
    render() {
        const { items, on_creating_loading, adminId } = this.state;
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <BreadCrumb items={items} />
                    </Col>
                    <Col span={24}>
                        <Card
                            style={{ marginBottom: "20px" }}
                            className="form"
                            title={<h3>{i18next.t("fuel_station.form")}</h3>}
                            bordered={false}
                            extra={
                                <MvBackButton
                                    path={`${CustomRoute.admin}/${adminId}/${CustomRoute.company_manage}`}
                                    type="primary"
                                    text={i18next.t("back")}
                                ></MvBackButton>
                            }
                        >
                            <Form
                                layout="vertical"
                                onFinish={this.saveFuelStation}
                            >
                                <Row gutter={24}>
                                    <Col lg={{ span: 12 }}>
                                        <Form.Item
                                            name="stationName"
                                            label={i18next.t('fuel_station_name')}
                                            rules={[{ required: true, message: "(*required)" }]}
                                        >
                                            <MvInput
                                                size="large"
                                                placeholder={i18next.t("fuel_station_name")}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }}>
                                        <Form.Item
                                            name="phoneNumber"
                                            label={i18next.t("phone_number")}
                                            rules={[
                                                {required: true, validator: validatePhoneNumber }
                                              ]}
                                        >
                                            <MvInput
                                                size="large"
                                                placeholder={i18next.t("phone_number")}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }}>
                                        <Form.Item
                                            name="longitude"
                                            label={i18next.t("longitude")}
                                            rules={[{ required: true, message: "(*required)" }]}
                                        >
                                            <MvInput size="large" placeholder={i18next.t("longitude")} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }}>
                                        <Form.Item
                                            name="latitude"
                                            label={i18next.t("latitude")}
                                            rules={[{ required: true, message: "(*required)" }]}
                                        >
                                            <MvInput size="large" placeholder={i18next.t("latitude")} />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 12 }}>
                                        <Form.Item
                                            name="status"
                                            label={i18next.t("status")}
                                        >
                                            <Radio.Group>
                                                <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                                                <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                    </Col>
                                    <Col lg={{ span: 24 }}>
                                        <Form.Item style={{ textAlign: "end" }}>
                                            <MvCreateButton loading={on_creating_loading} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}
export default compose(withRouter(FuelStationCreate)) 