import { message } from 'antd';
import axios from 'axios';
import { LOC_getRefreshToken } from '../core/custom-local-storage';
import Api from '../network/api';
import { notiError } from '../constants/notificationConfig';
import { updateAccessToken } from '../state/redux/authTokenSlice';
import CustomRoute from '../routes/CustomRoute';
import { createBrowserHistory } from 'history';

const axiosInstance = axios.create({
    baseURL: Api.host
});


export const Authenticate = async (username, password ) => {
    try {
        const response = await axiosInstance.post(Api.login, {
            "username": username,
            "password": password
        }, { headers: { 'accountType': 'ADMIN' } })
        return response
    } catch (error) {
        notiError(error.response.data.message)
        return error
    }
}

export const RefreshToken = async (props) => {

const history = createBrowserHistory();

    try {
        const refreshToken = LOC_getRefreshToken()
        const response = await axiosInstance.post(Api.refresh_token, {
            "refreshToken": refreshToken
        })
        if (response && response.status === 200) {
            props.dispatch(updateAccessToken(response.data))
            return response.data
        }else
        if (response && response.status === 401) {
            history.push(CustomRoute.login);
            return response.data
        }
    } catch (error) {
        message.destroy()
        message.error("Please Login Again!")
        history.push(CustomRoute.login);
        window.location.reload();
    }
}  