import React, { Component } from 'react';
import { Button, Result } from 'antd';
import { compose } from '@reduxjs/toolkit';
import withRouter from '../../network/withRouter';

class PageNotFound extends Component {
    render() {
      return (
        <Result
          status="404"
          title="404"
          subTitle="Sorry, the page you visited does not exist."
          extra={<Button onClick={()=> this.props.navigate("/")} type="primary">Back Home</Button>}
        />
      );
    }
  }

export default compose(withRouter(PageNotFound));