// import React, { useState } from 'react';
// import { Breadcrumb, Button, Col, Modal, Row, Select, Space, Table } from 'antd';
// import { Option } from 'antd/es/mentions';
// import { MvButton } from '../../components/common/Button/Button';

// const DriverCarAssignPage = () => {
//     const [data, setData] = useState([
//         {
//             key: '1',
//             carname: 'Bus',
//             drivername: 'Jack',
//         },
//         {
//             key: '2',
//             carname: 'Track',
//             drivername: 'Marry',
//         },
//         {
//             key: '3',
//             carname: 'Lorry',
//             drivername: 'unassign',
//         },
//         {
//             key: '4',
//             carname: 'Transhot',
//             drivername: 'unassign',
//         },
//     ]);
//     const [filteredInfo, setFilteredInfo] = useState({});
//     const [sortedInfo, setSortedInfo] = useState({});
//     const [selectedRowKeys, setSelectedRowKeys] = useState([]);
//     const [modalVisible, setModalVisible] = useState(false);
//     const [selectedDriver, setSelectedDriver] = useState('');

//     const handleChange = (pagination, filters, sorter) => {
//         console.log('Various parameters', pagination, filters, sorter);
//         setFilteredInfo(filters);
//         setSortedInfo(sorter);
//     };
//     const clearFilters = () => {
//         setFilteredInfo({});
//     };
//     const clearAll = () => {
//         setFilteredInfo({});
//         setSortedInfo({});
//     };
//     const setAgeSort = () => {
//         setSortedInfo({
//             order: 'descend',
//             columnKey: 'drivername',
//         });
//     };


//     const showModal = () => {
//         setModalVisible(true);
//     };

//     const handleModalOk = () => {
//         // Here you can handle adding the selected driver to the selected rows
//         console.log('Selected driver:', selectedDriver);
//         console.log('Selected rows:', selectedRowKeys);
//         updateSelectedRows(selectedDriver);
//         setModalVisible(false);
//     };

//     const handleModalCancel = () => {
//         setModalVisible(false);
//     };

//     const updateSelectedRows = (driverName) => {
//         setData(prevData => {
//             return prevData.map(item => {
//                 if (selectedRowKeys.includes(item.key)) {
//                     return {
//                         ...item,
//                         drivername: driverName
//                     };
//                 }
//                 return item;
//             });
//         });
//     };

//     const columns = [
//         {
//             title: 'CarName',
//             dataIndex: 'carname',
//             key: 'carname',
//             filters: [
//                 {
//                     text: 'Assign',
//                     value: 'assign',
//                 },
//                 {
//                     text: 'Unassign',
//                     value: 'unassign',
//                 },
//             ],
//             filteredValue: filteredInfo.carname || null,
//             onFilter: (value, record) => {
//                 if (value === 'assign') {
//                     return record.drivername !== 'unassign';
//                 } else if (value === 'unassign') {
//                     return record.drivername === 'unassign';
//                 }
//                 return true;
//             },
//             sorter: (a, b) => a.carname.length - b.carname.length,
//             sortOrder: sortedInfo.columnKey === 'carname' ? sortedInfo.order : null,
//             ellipsis: true,
//         },
//         {
//             title: 'DriverName',
//             dataIndex: 'drivername',
//             key: 'drivername',
//             sorter: (a, b) => a.age - b.age,
//             sortOrder: sortedInfo.columnKey === 'drivername' ? sortedInfo.order : null,
//             ellipsis: true,
//         },
//     ];

//     const onSelectChange = (newSelectedRowKeys) => {
//         console.log('selectedRowKeys changed: ', newSelectedRowKeys);
//         setSelectedRowKeys(newSelectedRowKeys);
//     };
//     const rowSelection = {
//         selectedRowKeys,
//         onChange: onSelectChange,
//     };
//     return (
//         <>
//             <Row>
//                 <Col span={1}></Col>
//                 <Col span={22}>
//                     <Row style={{ marginBottom: "10px" }}>
//                         <Breadcrumb items='' />
//                         <Col span={24} style={{ textAlign: "end" }}>
//                             <Space
//                                 style={{
//                                     marginBottom: 16,
//                                 }}
//                             >
//                                 <MvButton onClick={showModal} text={'Assign'}></MvButton>
//                             </Space>
//                             <Table rowSelection={rowSelection} columns={columns} dataSource={data} onChange={handleChange} />
//                         </Col>
//                     </Row>
//                 </Col>
//                 <Col span={1}></Col>
//             </Row>
//             <Modal
//                 title="Select Driver"
//                 visible={modalVisible}
//                 onOk={handleModalOk}
//                 onCancel={handleModalCancel}
//             >
//                 <p>Select driver:</p>   
//                 <Select
//                     style={{
//                         width: '100%',
//                     }}
//                     onChange={(value) => setSelectedDriver(value)}>
//                     <Option value="Jack">Jack</Option>
//                     <Option value="Marry">Marry</Option>
//                 </Select>
//             </Modal>
//         </>
//     );
// };
// export default DriverCarAssignPage;

import { Table } from 'antd'
import React from 'react'

const DriverCarAssignPage = () => {

  const columns = [

  ]
  return (
    <>
      <Table
        // bordered={bordered}
        virtual
        columns={columns}
        rowKey="id"
        // dataSource={empty ? [] : data}
        pagination={false}
        // ref={tblRef}
        rowSelection={
          {
            type: 'radio',
            columnWidth: 48,
          }
        }
        // expandable={expandableProps}
      />
    </>
  )
}

export default DriverCarAssignPage