import React, { useState, useEffect } from 'react';
import { Col, Form, Input, Row, Select } from 'antd';
import { nrcdata } from '../../../constants/nrc-data';
import { MvInput, MvSelectBox } from '../Input/Input';

const nrcode = [
  { id: '1', en: '1', mm: '၁' },
  { id: '2', en: '2', mm: '၂' },
  { id: '3', en: '3', mm: '၃' },
  { id: '4', en: '4', mm: '၄' },
  { id: '5', en: '5', mm: '၅' },
  { id: '6', en: '6', mm: '၆' },
  { id: '7', en: '7', mm: '၇' },
  { id: '8', en: '8', mm: '၈' },
  { id: '9', en: '9', mm: '၉' },
  { id: '10', en: '10', mm: '၈' },
  { id: '11', en: '11', mm: '၉' },
  { id: '12', en: '12', mm: '၈' },
  { id: '13', en: '13', mm: '၉' },
  { id: '14', en: '14', mm: '၉' },
];

const { Option } = Select;

const NRCInput = ({ name, label, required, initialNRCData }) => {
  const [selectedNRC, setSelectedNRC] = useState(initialNRCData?.nrcCode || null);

  const filteredCities = nrcdata.filter((city) => city.nrc_code === selectedNRC);

  useEffect(() => {
    if (initialNRCData?.nrcCode) {
      setSelectedNRC(initialNRCData.nrcCode);
    }
  }, [initialNRCData]);

  const handleNRCChange = (value) => {
    setSelectedNRC(value);
  };

  return (
    <Form.Item label={label} required={required}>
      <Row gutter={16} align="middle">
        <Col span={4}>
          <Form.Item
            name={[name, 'nrcCode']}
            noStyle
            rules={[{ required: required, message: 'NRC region code is required' }]}
          >
            <MvSelectBox
              placeholder="Select NRC Code"
              onChange={handleNRCChange}
              value={selectedNRC}
            >
              {nrcode.map((code) => (
                <Option key={code.id} value={code.id}>
                  {code.en}
                </Option>
              ))}
            </MvSelectBox>
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name={[name, 'township']}
            noStyle
            rules={[{ required: required, message: 'City code is required' }]}
          >
            <MvSelectBox
              placeholder="Select City"
              style={{ width: '100%' }}
              disabled={!selectedNRC}
            >
              {filteredCities.map((city) => (
                <Option key={city.code} value={city.name_en}>
                  {city.name_en}
                </Option>
              ))}
            </MvSelectBox>
          </Form.Item>
        </Col>

        <Col span={4}>
          <Form.Item
            name={[name, 'nrcType']}
            noStyle
            rules={[{ required: required, message: 'NRC type is required' }]}
          >
            <MvSelectBox placeholder="Type" style={{ width: '100%' }}>
              <Option value="N">N</Option>
              <Option value="E">E</Option>
            </MvSelectBox>
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item
            name={[name, 'nrcNumber']}
            noStyle
            rules={[{ required: required, message: 'NRC number is required' }]}
          >
            <MvInput
              style={{ width: '100%' }}
              placeholder="Enter NRC Number"
              maxLength={6}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default NRCInput;
