import { createSlice } from "@reduxjs/toolkit";
import { setCookie } from './authService';


export const authTokenSlice = createSlice({
  name: "authedya",
  initialState: {
    accessToken: "",
    refreshToken: "",
    authenticated: false,
    hasAccessToken: false,
  },
  reducers: {
    updateAccessToken: (state, action) => {
      console.log(action.payload.refreshToken);
      state.accessToken = action.payload.accessToken;
      console.log("sliceAccessToken:", action.payload.accessToken)
      state.refreshToken = action.payload.refreshToken;
      console.log(state.authenticated);
      if (state.accessToken !== null && state.accessToken !== undefined) {
        state.authenticated = true;
        state.hasAccessToken = true;
      } else {
        state.authenticated = false;
        state.hasAccessToken = false;
      }
    },
    logoutAndResetReduxState: (state, action) => {
      state.accessToken = "";
      state.refreshToken = "";
      state.authenticated = false;
      // state.hasAccessToken = false;
    },
    setHasAccessToken: (state, action) => {
      // state.hasAccessToken = false;
    },
    setAccessToken: (accessToken) => (dispatch) => {
      dispatch(updateAccessToken(accessToken));
      console.log("reduxAccessToken:", accessToken)

      setCookie('access_token', accessToken, 30);
    }
  },
});

export const {
  updateAccessToken,
  setAccessToken,
  setRefreshToken,
  clearTokens,
  logoutAndResetReduxState,
  setHasAccessToken,
} = authTokenSlice.actions;
export const accessToken = (state) => state.auth.accessToken;
export const refreshToken = (state) => state.auth.refreshToken;
export const authenticated = (state) => state.auth.authenticated;
export default authTokenSlice.reducer;
