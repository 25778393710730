import { App, Button, Checkbox, Col, Form, Grid, Input, Typography } from "antd";
import Layout, { Content } from "antd/es/layout/layout";
import { Header } from "antd/lib/layout/layout";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { theme } from "../../assets/theme";
import EdLogo from "../../components/core-component";
import { LOC_getRefreshToken, LOC_store_token } from "../../core/custom-local-storage";
import { Authenticate } from "../../network/authApi";
import CustomRoute from "../../routes/CustomRoute";

const { useBreakpoint } = Grid;
const { Text, Title, Link } = Typography;

const Login = (props) => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    token: { colorBgContainer, colorBgContent },
    components: { Header: headerTheme, Content: contentTheme },
  } = theme;

  const onLogin = async (values) => {

    const requestData = {
      username: values.username,
      password: values.password
    }

    try {
      const response = await Authenticate(values.username, values.password)
      if (response.status == 200) {
        LOC_store_token(response.data)
        navigate(CustomRoute.admin)
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <App>
      <Layout>
        <Header
          theme='light'
          style={{
            background: headerTheme.colorBgContainer, // Use custom background color from theme
            color: headerTheme.colorPrimary, // Use custom text color from theme
            height: "75px",
            display: "flex",
            alignItems: "center",
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            paddingLeft: 10,
            paddingRight: 10,
            zIndex: 2,
            boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
          }}
        >
          <Col span={4} style={{ paddingTop: '25px' }}>
            <EdLogo />
          </Col>
          <Col span={16}></Col>
          <Col span={4} style={{ textAlign: 'end', paddingRight: '30px' }}>
            <a href="" style={{ textDecoration: 'none', color: '', fontSize: '15px' }}>Sign Up</a>
          </Col>
        </Header>
        <Content
          style={{
            position: 'fixed',
            padding: 24,
            margin: 0,
            minHeight: 500,
            background: contentTheme.colorLgContainer,
            top: 65,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'scroll',
            padding: "0 520px 200px 520px",
          }}
        >
          <Title style={{ display: 'flex', justifyContent: "center", margin: '50px' }}>Login</Title>
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={onLogin}
            layout="vertical"
            requiredMark="optional"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[
                {
                  type: "username",
                  required: true,
                  message: "Please input your Name!",
                },
              ]}
            >
              <Input
                style={{ height: "40px" }}
                placeholder="Username"
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your Password!",
                },
              ]}
            >
              <Input.Password
                style={{ height: "40px" }}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Remember me</Checkbox>
              </Form.Item>
              <a href="">
                Forgot password?
              </a>
            </Form.Item>
            <Form.Item style={{ marginBottom: "0px" }}>
              <Button block="true" type="primary" htmlType="submit">
                Log in
              </Button>
            </Form.Item>
          </Form>
        </Content>
      </Layout>
    </App>
  );
}
export default Login;
