const CustomRoute = {
    login : "/login",
    admin: "/admin",
    admin_create: "/admin/admincreate",
    admin_update_param: "/admin/adminupdate/:id",

    driver: "driver",
    driver_param: "company/:companyid/driver",
    driver_create: "drivercreate",
    driver_create_param: "/admin/:adminid/company/:companyid/drivercreate",
    driver_update: "driverupdate",
    driver_update_param: "/admin/:adminid/company/:companyid/driverupdate/:id",

    vehicle: "vehicle",
    vehicle_param: "company/:companyid/vehicle",
    vehicle_create: "vehiclecreate",
    vehicle_create_param: "/admin/:adminid/company/:companyid/vehiclecreate",
    vehicle_update: "vehicleupdate",
    vehicle_update_param: "admin/:adminid/company/:companyid/vehicleupdate/:id",

    
    fuel_station: "fuelstation",
    fuel_station_param: "company/:companyid/fuelstation",
    fuel_station_create: "fuelstationcreate",
    fuel_station_create_param: "/admin/:adminid/company/:companyid/fuelstationcreate",
    fuel_station_update: "fuelstationupdate",
    fuel_station_update_param: "/admin/:adminid/company/:companyid/fuelstationupdate/:id",

    driver_car_assign: "/drivercarassignpage",

    company: "/company",
    company_param : "company",
    company_create: "/company/companycreate",
    company_create_param: "/company/companycreate",
    company_update: "/company/companyupdate",
    company_update_param: "/company/companyupdate/:id",
    company_driver_car_assign: "/company/drivercarassign",

    company_manage:'companymanage',
    company_manage_param:'/admin/:adminid/companymanage',


    faq: "/faqpage",
    faq_create: "/faqpage/faqcreate",
    faq_update_param: "/faqpage/faqupdate/:id",

    nearby: "/nearbypage",
    nearby_create: "/nearbypage/nearbycreate",
    nearby_update: "/nearbypage/nearbyupdate",
    nearby_update_param: "/nearbypage/nearbyupdate/:id",

    fuel_staff: "fuelstaff",
    fuel_staff_param: "admin/:adminid/fuelstation/:stationid/fuelstaff",
    fuel_staff_create: "fuelstaffcreate",
    fuel_staff_create_param: "admin/:adminid/fuelstation/:stationid/fuelstaff/fuelstaffcreate",
    fuel_staff_update: "fuelstaffupdate",
    fuel_staff_update_param: "admin/:adminid/fuelstation/:stationid/fuelstaff/fuelstaffupdate/:id",

    fuel_point_history : "/fuelpointhistory",

    driver_point_history : '/dirverpointhistory',

    station_point_history : '/stationpointhistory', 
    station_point_history_update : '/stationpointhistory/stationpointhistoryupdate',
    station_point_history_update_param : '/stationpointhistory/stationpointhistoryupdate/:id',

    fuel_company_point_history : '/fuelcompanypointhistory'

}
  
  export default CustomRoute;
  