import { compose } from '@reduxjs/toolkit';
import { Button, Card, Col, Form, Radio, Row } from 'antd';
import i18next from "i18next";
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/common/BreadCrumb';
import { MvBackButton, MvUpdateButton } from '../../components/common/Button/Button';
import { MvDatePicker, MvInput, MvPasswordBox, MvTextarea } from '../../components/common/Input/Input';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import CustomRoute from '../../routes/CustomRoute';
import NRCInput from '../../components/common/nrc-input/NRCInput';
import { parseNRC, validatePhoneNumber } from '../../constants/validation';
import dayjs from 'dayjs';

class CompanyUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      userType: '',
      on_update_loading: false,
      items: [
        {
          title: <Link to={CustomRoute.admin}>{i18next.t("home")}</Link>,
        },
        {
          title: <Link to={CustomRoute.company}>{i18next.t("company")}</Link>,
        },
        {
          title: i18next.t("admin.update"),
        },
      ],

      loading: true,
      accountId: null
    };
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.fetchData();

  }

  fetchData = async () => {
    const id = this.props.params.id
    try {
      const response = await ApiInstance(Api.admin, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, null, id);

      const initialNRCData = parseNRC(response.nrc);
    
      let admin;
      admin = { ...response };

      this.setState((prevState) => ({
        data: admin,
        accountId: admin.accounts[0].id,
        userType: response.userType
      }));

      this.formRef.current.setFieldsValue({...admin , nrc: initialNRCData});
    } catch (error) { }
  };

  updateAdmin = async (values) => {

    
    const { nrc } = values;

    const formattedNRC = `${nrc.nrcCode}/${nrc.township}(${nrc.nrcType})${nrc.nrcNumber}`;

    const { data, accountId } = this.state;
    this.setState({
      on_update_loading: true,
    })

    const requestData = {
      "id": data.id,
      "uniqueId": values.uniqueId,
      "firstName": values.firstName,
      "lastName": values.lastName,
      "dateOfBirth": values.dateOfBirth,
      "emailAddress": values.emailAddress,
      "phoneNumber": values.phoneNumber,
      "address": values.address,
      "nrc": formattedNRC,
      "gender": values.gender,
      "status": values.status,
      "userType" :  values.userType,
      "description" : values.description,
      "accounts": [
        {
          "loginId": values.phoneNumber,
          "password": values.password,
          "accountType": "PHONE"
        }
      ],
      "company": {
        "companyName": values.company.companyName,
        "companyRegistrationNo": values.company.companyRegistrationNo,
        "address": values.company.address,
        "phoneNumber": values.company.phoneNumber,  
        "emailAddress": values.company.emailAddress,
        "ownerName": values.company.ownerName,
        "status": values.company.status, 
        "profileUrl": values.company.profileUrl 
      }
    }

    try {
      const response = await ApiInstance(Api.admin, HTTP_METHOD.PUT, MEDIA_TYPE.JSON, requestData, null);
      if (response.status == 200) {
        this.props.navigate(CustomRoute.company)
      }
    } catch (error) {
      this.setState({
        on_update_loading: false
      })
    }
  };

  userTypeChange = ({ target: { value } }) => {
    this.setState({
      userType: value
    })
  }

  handleNrcChange = (formattedNrc) => {
    this.formRef.current.setFieldsValue({ nrc: formattedNrc });
  };

  handleDateChange = (date, dateString) => {
    if (this.formRef?.current) {
      this.formRef.current.setFieldsValue({ dateOfBirth: dateString });
    }
  };


  render() {
    const { items, on_update_loading, userType, data } = this.state;
    return (
      <>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <BreadCrumb items={items} />
          </Col>
          <Col span={24}>
            <Card
              className="form"
              title={<h3>{i18next.t("companyadmin.update")}</h3>}
              bordered={false}
              extra={
                <MvBackButton
                  path={CustomRoute.company}
                  type="primary"
                  text={i18next.t("back")}
                ></MvBackButton>}
            >
              <Form
                layout="vertical"
                onFinish={this.updateAdmin}
                ref={this.formRef}
              >
                <Row gutter={24}>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="uniqueId"
                      label={i18next.t("unique_id")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("unique_id")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="firstName"
                      label={i18next.t("first_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("first_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="lastName"
                      label={i18next.t("last_name")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <MvInput
                        size="large"
                        placeholder={i18next.t("last_name")}
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="emailAddress"
                      label={i18next.t("email")}
                      rules={[{ required: true, message: "(*required)" },
                        {
                          type: 'email',
                          message: 'The input is not a valid email!',
                        },]}
                    >
                      <MvInput size="large" placeholder={i18next.t("email")} />
                    </Form.Item>
                  </Col>
                  
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name={'phoneNumber'}
                      label={i18next.t("phone_number")}
                      rules={[
                        {required: true, validator: validatePhoneNumber }
                      ]}
                    >
                      <MvInput size="large" placeholder="979xxxxxxx" />
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name='password'
                      label={i18next.t("password")}
                    >
                      <MvPasswordBox
                        size="large"
                        placeholder={i18next.t("password_placeholder")}
                      />
                    </Form.Item>
                  </Col>
                  
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="dateOfBirth"
                      label={i18next.t("date_of_birth")}
                    >
                      <MvDatePicker handleDateChange={this.handleDateChange} value={data?.dateOfBirth}/>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                      <NRCInput name="nrc" label="NRC" required />
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      name="gender"
                      label={i18next.t("gender")}
                    >
                      <Radio.Group>
                        <Radio value="MALE">{i18next.t("male")}</Radio>
                        <Radio value="FEMALE">{i18next.t("female")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      className='status'
                      name="status"
                      label={i18next.t("status")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <Radio.Group>
                        <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                        <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col lg={{ span: 12 }}>
                    <Form.Item
                      className='userType'
                      name="userType"
                      label={i18next.t("user_type")}
                      rules={[{ required: true, message: "(*required)" }]}
                    >
                      <Radio.Group
                        onChange={this.userTypeChange}
                      >
                        <Radio value="FUEL_ADMIN">{i18next.t("fuel_admin")}</Radio>
                        <Radio value="COMPANY_ADMIN">{i18next.t("company_admin")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  {
                    userType &&
                    <>
                      {
                        userType === "COMPANY_ADMIN" && (
                          <>
                            <Col lg={{ span: 24 }}>
                              <h2 style={{ marginTop: 0 }}>Company</h2>
                            </Col>
                            <Col lg={{ span: 12 }}>
                              <Form.Item
                                name={["company","companyName"]}
                                label={i18next.t("company_name")}
                                rules={[{ required: true, message: "(*required)" }]}
                              >
                                <MvInput
                                  size="large"
                                  placeholder={i18next.t("company_name")}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )
                      }
                      {
                        userType === "FUEL_ADMIN" && (
                          <>
                            <Col lg={{ span: 24 }}>
                              <h2 style={{ marginTop: 0 }}>{i18next.t("fuel_company")}</h2>
                            </Col>
                            <Col lg={{ span: 12 }}>
                              <Form.Item
                                name={["company", "companyName"]}
                                label={i18next.t("fuel_company_name")}
                                rules={[{ required: true, message: "(*required)" }]}
                              >
                                <MvInput
                                  size="large"
                                  placeholder={i18next.t("fuel_company_name")}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )
                      }
                      <Col lg={{ span: 12 }}>
                        <Form.Item
                          name={["company","companyRegistrationNo"]}
                          label={i18next.t("registration_no")}
                        >
                          <MvInput
                            size="large"
                            placeholder={i18next.t("registration_no")}
                          />
                        </Form.Item>
                      </Col>
                      <Col lg={{ span: 12 }}>
                        <Form.Item
                          name={["company","phoneNumber"]}
                          label={i18next.t("phone_number")}
                          rules={[
                            {required: true, validator: validatePhoneNumber }
                          ]}
                        >
                          <MvInput size="large" placeholder="979xxxxxxx" />
                        </Form.Item>
                      </Col>
                      <Col lg={{ span: 12 }}>
                        <Form.Item
                          name={["company","emailAddress"]}
                          label={i18next.t("email")}
                          rules={[{ required: true, message: "(*required)" }]}
                        >
                          <MvInput size="large" placeholder={i18next.t("email")} />
                        </Form.Item>
                      </Col>
                      <Col lg={{ span: 12 }}>
                        <Form.Item
                          name={["company","ownerName"]}
                          label={i18next.t("owner_name")}
                          rules={[{ required: true, message: "(*required)" }]}
                        >
                          <MvInput size="large" placeholder={i18next.t("owner_name")} />
                        </Form.Item>
                      </Col>
                      <Col lg={{ span: 12 }}>
                        <Form.Item
                          className='status'
                          name={["company","status"]}
                          label={i18next.t("status")}
                          rules={[{ required: true, message: "(*required)" }]}
                        >
                          <Radio.Group>
                            <Radio value="ACTIVE">{i18next.t("active")}</Radio>
                            <Radio value="INACTIVE">{i18next.t("inactive")}</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col lg={{ span: 24 }}>
                        <Form.Item
                          name={["company","address"]}
                          label={i18next.t("address")}
                        >
                          <MvTextarea size="large" placeholder={i18next.t("address")} />
                        </Form.Item>
                      </Col>
                    </>
                  }
                </Row>
                <Form.Item style={{ textAlign: "end" }}>
                  <MvUpdateButton loading={on_update_loading} />
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(
  withRouter
)(CompanyUpdate);