import { Button, Col, DatePicker, Row, Select, Spin, Table, Skeleton } from 'antd'
import dayjs from 'dayjs'
import i18next from 'i18next'
import React, { Component } from 'react'
import { EdSearchBox } from '../../components/core-component'
import { TitleLevel3 } from '../../components/general-component'
import { constant } from '../../constants/constant'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiInstance } from '../../network/network-manager'
import pageNotFound from '../ErrorPages/pageNotFound'

const { RangePicker } = DatePicker;

class FuelPointHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyPage: 0,
            page: constant.page,
            pageSize: constant.pageSize,
            stationPage: 0,
            pageNumber: 0,
            totalPagination: 0,
            searchValue: [],
            searchField: [
                { field: 'fromUserName', value: '' },
                { field: 'toUserName', value: '' },
                { field: 'points', value: '' },
                { field: 'date', value: '' },
            ],
            data_filter_params: {
                'fromUserName': '', 'toUserName': '', 'points': '', 'fromDate': null,
                'toDate': null,
            },
            prevSearchTerm: '',
            data: [],
            totalCompany: [{ company: { companyName: 'ALL', companyType: 'ALL' }, stationName: 'ALL' }],
            loading: false,
            companyType: '',
            totalStation: [{stationName : 'ALL'}],
            stationValue: undefined,
            stationId: 3,
            moreCompany: true,
            all: { company: { companyName: 'ALL', companyType: 'ALL' }, stationName: 'ALL' },
            loading: true,
            page: 1,
            hasMore: true,
            companyColumn: [
                {
                    title: 'No.',
                    key: 'index',
                    render: (value, item, index) => <span>{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
                    width: 5
                },
                {
                    title: i18next.t('from_user'),
                    dataIndex: ['fromUser', 'fullName'],
                    key: 'fromUser',
                },
                {
                    title: i18next.t('to_user'),
                    dataIndex: ['toUser', 'fullName'],
                    key: 'toUser',
                },
                {
                    title: i18next.t('points'),
                    dataIndex: 'fuelPoint',
                    key: 'point',
                },
                {
                    title: i18next.t('date'),
                    dataIndex: 'date',
                    key: 'date',
                    render: (date) => dayjs(date).format('YYYY-MM-DD'),
                },
                {
                    title: i18next.t('history_type'),
                    dataIndex: 'historyType',
                    key: 'historyType',
                },
            ],
            stationColumn: [
                {
                    title: 'No.',
                    key: 'index',
                    render: (value, item, index) => <span>{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
                    width: 5
                },
                {
                    title: i18next.t('from_user'),
                    dataIndex: ['fromUser', 'fullName'],
                    key: 'admin',
                },
                {
                    title: i18next.t('to_user'),
                    dataIndex: ['toUser', 'fullName'],
                    key: 'driver',
                },
                {
                    title: i18next.t('station'),
                    dataIndex: ['station'],
                    key: 'station',
                },
                {
                    title: i18next.t('points'),
                    dataIndex: 'fuelPoint',
                    key: 'point',
                },
                {
                    title: i18next.t('date'),
                    dataIndex: 'date',
                    key: 'date',
                    render: (date) => dayjs(date).format('YYYY-MM-DD'),
                },
                {
                    title: i18next.t('history_type'),
                    dataIndex: 'historyType',
                    key: 'historyType',
                    render: (type) => type === 'USED' ? "Driver Used" : type
                },
            ]
        }
    }

    componentDidMount() {
        this.fetchData();
        this.fetchCompany(0);
    }

    fetchData = async (pageNumber, value) => {

        const { page, searchValue, pageSize, companyId, stationId, data_filter_params } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            const params = {
                companyId: companyId,
                page: pageNumber ? pageNumber - 1 : page - 1,
                ...data_filter_params
            };
            const response = await ApiInstance(Api.fuel_point_history, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)

            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : page,
                    loading: false
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false,
            searchValue: value ? value : searchValue
        })
    }

    search = async (pageNumber, value) => {
        const { page, searchTerm, data_filter_params, companyType, stationId, searchValue } = this.state
        this.setState({
            isDataFetching: true
        })
        try {

            const params = {
                ...data_filter_params,
                ...(companyType === 'FLEET' ? { fuelStationId: '' } : {}),
                page: pageNumber ? pageNumber - 1 : page - 1,
            };

            const response = await ApiInstance(Api.fuel_point_history, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: 1,
                    prevSearchTerm: searchTerm
                }));
            }
        } catch (error) {
        }

        const params = {
            ...data_filter_params,
            fuelStationId: companyType === 'FUEL' ? stationId : ''
        };

        this.setState({
            isDataFetching: false,
            data_filter_params: params,
            searchValue: value ? value : searchValue
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    changeSearchName = (event, searchName) => {
        let { data_filter_params, searchField } = this.state;

        const updatedSearchValues = searchField.map((item) => {
            if (item.field === searchName) {
                return { ...item, value: searchName };
            }
            return item;
        });


        data_filter_params = {
            ...data_filter_params,
            [searchName]: event.target.value
        };

        this.setState({
            data_filter_params: data_filter_params,
            searchTerm: event.target,
            page: 1,
        });

        if (event.target.value === '' && this.state.prevSearchTerm) {
            this.setState({
                data_filter_params: {
                  ...data_filter_params,
                  [searchName]: '',
                },
                prevSearchTerm: ''
              }, () =>
                this.search()
              );

        }
    }

    handleDateChange = (dates) => {
        const { data_filter_params, pagination } = this.state

        const fromDate = dates ? dates[0].format('YYYY-MM-DDTHH:mm:ss') : null;
        const toDate = dates ? dates[1].format('YYYY-MM-DDTHH:mm:ss') : null;

        this.setState(
            {
                data_filter_params: {
                    ...data_filter_params,
                    fromDate,
                    toDate,
                    page: 1, 
                },
                pagination: {
                    ...pagination,
                    current: 1, 
                },
            },
            () => {
                this.search();
            }
        );
    };


    filterByCompany = (value, option) => {

        let { data_filter_params, pageNumber } = this.state;

        data_filter_params = {
            ...data_filter_params,
            companyId: value,
            historyType: '',
            fuelStationId: ''
        }

        this.setState({
            data_filter_params: data_filter_params,
            companyId: value,
            companyType: option.companyType,
            stationValue: undefined,
            pageNumber: 0,
            page: 1
        }, () => this.search()
        )
        if (option.companyType === 'FUEL') {
            this.fetchStation(value)
        }
    }

    handleKeyDown = (e) => {
        this.search();
    };

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    fetchCompany = async (pageNumber) => {

        const { all } = this.state
        const { page, data_filter_params } = this.state;
        const params = {
            ...data_filter_params,
            page: pageNumber ? pageNumber - 1 : page - 1,
            userType: "COMPANY_ADMIN"
        };
        const response = await ApiInstance(Api.admin_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)

        this.setState({
            totalCompany: [all, ...response.content],
            loading: false,
        });

        return response.content;
    }


    handlePopupScroll = (e) => {

        const { companyPage, moreCompany } = this.state

        const { target } = e;

        if (target.scrollTop + target.offsetHeight === target.scrollHeight && moreCompany) {
            this.loadMoreData(companyPage + 1);
        }
    };

    loadMoreData = async (page) => {
        const { pageSize, totalCompany } = this.state;

        this.setState({ loading: true });

        const params = {
            page,
            pageSize
        };

        try {
            const response = await ApiInstance(Api.admin_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)

            const data = response.content;
            if (data.length > 0) {
                this.setState((prevState) => {

                    const updatedData = [{ company: { companyName: 'ALL', companyType: 'ALL' }, stationName: 'ALL' },
                    ...prevState.totalCompany.slice(1),
                    ...response.content];
                    return {
                        totalCompany: updatedData,
                        loading: false,
                        page,
                    }

                });
            } else {
                console.log("error>")
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            this.setState({ loading: false });
        }
    };

    fetchStation = async (id, page) => {
        const { stationPage } = this.state

        const params = {
            companyId: id,
            page: stationPage
        };
        
        await ApiInstance(Api.fuel_station_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            .then(response => {
                console.log(response)
                this.setState((prevState) => ({
                    totalStation: [...prevState.totalStation, ...response.content],
                    loading: false,
                    hasMore:  response.content.length >= 20
                  }));
                })
            .catch(error => {
                this.setState({

                })
            })
    }

    filterByStation = (value, option) => {
        let { data_filter_params } = this.state;

        data_filter_params = {
            ...data_filter_params,
            fuelStationId: value
        }
        this.setState({
            data_filter_params: data_filter_params,
            stationId: value,
        }, () => this.search()
        )

    }

    stationPopupScroll = (e) => {
        const {hasMore} = this.state

        const { target } = e;
        const buffer = 10;
        if (target.scrollTop + target.offsetHeight + buffer >= target.scrollHeight && this.state.hasMore && !this.state.loading){
            this.setState((prevState) => ({ stationPage: prevState.stationPage + 1 }), () => {
                this.fetchStation(null, this.state.stationPage);
            });
        }
    }

    filterByHistoryType = (value, option) => {

        const { data_filter_params, stationId, companyType } = this.state;

        let params;

        params = {
            ...data_filter_params,
            historyType: value === "ALL" ? '' : value,
            fuelStationId: companyType === 'FUEL' ? stationId : ''
        }

        this.setState({
            data_filter_params: params
        }, () => this.search(value)
        )
    }



    render() {
        const { data, page, pageSize, loading,
            totalCompany, totalPagination, companyType,
            totalStation, stationValue,
            companyColumn } = this.state

        // console.log("total:", totalCompany)

        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: i18next.t('from_user'),
                dataIndex: ['fromUser', 'fullName'],
                key: 'fromUser',
            },
            {
                title: i18next.t('to_user'),
                dataIndex: ['toUser', 'fullName'],
                key: 'toUser',
            },
            {
                title: i18next.t('points'),
                dataIndex: 'fuelPoint',
                key: 'point',
            },
            {
                title: i18next.t('date'),
                dataIndex: 'createdDate',
                key: 'date',
                // render: (date) => dayjs(date).format('YYYY-MM-DD'),
            },
            {
                title: i18next.t('history_type'),
                dataIndex: 'historyType',
                key: 'historyType',
                render: (type) => type === 'USED' ? "Driver Used" : type
            },
        ]



        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <TitleLevel3 label={i18next.t("fuel_point_history")} />
                    </Col>
                    <Col span={4}>
                        <EdSearchBox
                            size="middle"
                            onChange={(values) =>
                                this.changeSearchName(values, "fromUserName")}
                            placeholder="SearchByFromUserName"
                            onPressEnter={this.handleKeyDown}
                        />
                    </Col>
                    <Col span={4}><EdSearchBox
                        size="middle"
                        onChange={(values) => this.changeSearchName(values, "toUserName")}
                        placeholder="SearchByToUserName"
                        onPressEnter={this.handleKeyDown}
                    />
                    </Col>
                    <Col span={7}>
                        <Button size="large" shape="round" onClick={() => this.search()} type="primary" >{i18next.t('search')}</Button>
                    </Col>
                    <Col span={5}>
                        <RangePicker
                            format="YYYY-MM-DD"
                            onChange={this.handleDateChange}
                            style={{ height: '40px' }}
                        />
                    </Col>


                    <Col span={4} style={{ textAlign: 'end' }}>
                        <Select
                            showSearch
                            style={{ height: 40, width: '100%', textAlign: 'start' }}
                            placeholder="Filter By Company"
                            notFoundContent={loading ? <Spin size="small" /> : null}
                            // onPopupScroll={this.handlePopupScroll}
                            onChange={this.filterByCompany}
                            dropdownStyle={{ width: 300, height: 400, fontSize: 40 }}
                            listHeight={600}

                        >
                            {totalCompany.map(option => (
                                <Select.Option style={{ height: 40, fontSize: 15 }} key={option.company.id} companyType={option.company.companyType}>
                                    {option.company.companyName}
                                </Select.Option>
                            ))}
                        </Select>
                    </Col>
                    {
                        companyType === "FUEL" &&
                        <>
                            <Col span={16} style={{ textAlign: 'end' }} />
                            <Col span={4} style={{ textAlign: 'end' }}>
                                <Select
                                    showSearch
                                    style={{ height: 40, width: '100%', textAlign: 'start' }}
                                    placeholder="Filter By Station"
                                    onChange={this.filterByStation}
                                    value={stationValue}
                                    dropdownStyle={{ width: 300, maxHeight: 350, fontSize: 40 }}
                                    // maxTagCount={40}
                                    listHeight={350}
                                    onPopupScroll={this.stationPopupScroll}
                                >
                                    {totalStation.map(option => (
                                        <Select.Option style={{ height: 40, fontSize: 15 }} key={option.id}>
                                            {option.stationName}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Col>
                            <Col span={4} style={{ textAlign: 'end' }}>
                                <Select
                                    showSearch
                                    style={{ height: 40, width: '100%', textAlign: 'start' }}
                                    placeholder="Filter By HistoryType"
                                    onChange={this.filterByHistoryType}
                                    options={[
                                        {
                                            value: 'ALL',
                                            label: 'ALL',
                                        },
                                        {
                                            value: 'EXCHANGE',
                                            label: 'EXCHANGE',
                                        },
                                        {
                                            value: 'USED',
                                            label: 'USED',
                                        },
                                    ]}
                                >

                                </Select>
                            </Col>
                        </>
                    }
                    {
                        companyType === "FLEET" &&
                        <>
                            <Col span={20}></Col>
                            <Col span={4} style={{ textAlign: 'end' }}>
                                <Select
                                    showSearch
                                    style={{ height: 40, width: '100%', textAlign: 'start' }}
                                    placeholder="Filter By HistoryType"
                                    onChange={this.filterByHistoryType}
                                    options={[
                                        {
                                            value: 'ALL',
                                            label: 'ALL',
                                        },
                                        {
                                            value: 'TOPUP',
                                            label: 'TOPUP',
                                        },
                                        {
                                            value: 'REQUEST',
                                            label: 'REQUEST',
                                        },
                                    ]}
                                >

                                </Select>
                            </Col>
                        </>
                    }
                    <Col lg={{ span: 24 }}>
                    {loading ? (
              <Skeleton active />
            ) : (
                        <Table pagination={{
                            position: "top" | "bottom",
                            total: totalPagination,
                            current: page,
                            onChange: this.handlePaginationChange,
                            defaultPageSize: pageSize,
                            pageSizeOptions: [10, 20]
                        }}
                            dataSource={data} columns={companyType === 'FLEET' ? companyColumn
                                // : companyType === 'FUEL' ? ''
                                : columns} />
                            )}  
                    </Col>
                </Row>
            </>
        )
    }
}

export default FuelPointHistory