import { EditOutlined, PlusCircleFilled } from '@ant-design/icons';
import { compose } from '@reduxjs/toolkit';
import { Button, Col, Row, Skeleton, Space, Table, Tag } from 'antd';
import i18next from 'i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { MvButton } from '../../components/common/Button/Button';
import { EdSearchBox } from '../../components/core-component';
import { TitleLevel3 } from '../../components/general-component';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiInstance } from '../../network/network-manager';
import withRouter from '../../network/withRouter';
import CustomRoute from '../../routes/CustomRoute';
import { constant } from '../../constants/constant';

class CompanyPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deleteItem: "",
            data: [],
            page: constant.page,
            pageSize: constant.pageSize,
            pageNumber: 0,
            totalPagination: '',
            searchValue: [],
            data_filter_params: '',
            prevSearchTerm: '',
            columns: [
                {
                    title: 'No.',
                    key: 'index',
                    render: (value, item, index) => <span>{(this.state.page - 1) * this.state.pageSize + index + 1}</span>,
                    width: 5
                },
                {
                    title: i18next.t('name'),
                    dataIndex: 'fullName',
                    key: '1',
                },
                {
                    title: i18next.t('phone_number'),
                    dataIndex: ['accounts', 0, 'loginId'],
                    key: '2',
                },
                {
                    title: i18next.t('email'),
                    dataIndex: 'emailAddress',
                    key: '3',
                },
                {
                    title: i18next.t('company_type'),
                    dataIndex: ['company', 'companyType'],
                    key: '4',
                },
                {
                    title: i18next.t('company'),
                    dataIndex: ['company', 'companyName'],
                    key: '5',
                },
                {
                    title: i18next.t('status'),
                    dataIndex: 'status',
                    key: '6',
                    render: (status) => (
                        <Tag color={status === 'ACTIVE' ? 'green' : 'red'}>
                            {status}
                        </Tag>
                    )
                },
                {
                    title: i18next.t('manage_company'),
                    dataIndex: '',
                    key: 'manage_company',
                    render: (admin) => (
                        <Link to={`${CustomRoute.admin}/${admin.id}/${CustomRoute.company_manage}   `}>
                            <a>{i18next.t("manage")}</a>
                        </Link>
                    )
                },
                {
                    title: i18next.t('action'),
                    dataIndex: '',
                    key: '9',
                    textAlign: 'center',
                    width: 60,
                    render: (company) => (
                        <Space>
                            <Link to={`${CustomRoute.company_update}/${company.id}`}>
                                <Button size={"medium"} type="primary" shape="circle">
                                    <EditOutlined />
                                </Button>
                            </Link>
                        </Space>
                    )
                },
            ],
            items: [
                {
                    title: i18next.t("home"),
                },
                {
                    title: i18next.t('company'),
                },
            ],
            loading: true
        };
    }

    componentDidMount() {
        this.fetchData();

    }

    fetchData = async (pageNumber) => {
        const { page, data_filter_params } = this.state;
        const params = {
            ...data_filter_params,
            page: pageNumber ? pageNumber - 1 : page - 1,
            userType: "COMPANY_ADMIN"
        };
        await ApiInstance(Api.admin_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            .then(response => {
                this.setState({
                    data: response?.content,
                    totalPagination: response?.totalElements,
                    page: pageNumber ? pageNumber : page,
                    loading: false
                })
            })
            .catch(error => {
                this.setState({
                    loading: false
                })
            })
    }

    search = async () => {
        const { page, pageSize, searchTerm, data_filter_params, pageNumber } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            const params = {
                ...data_filter_params,
                userType: "FUEL_ADMIN"
            };

            const response = await ApiInstance(Api.admin_filter, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, params)
            if (response && response.content) {
                this.setState(prevState => ({
                    data: response.content,
                    totalPagination: response.totalElements,
                    page: page,
                    prevSearchTerm: searchTerm
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    handlePaginationChange = (pageNumber, pageSize) => {
        this.fetchData(pageNumber);
    };

    changeSearchName = (event, searchName) => {
        let { data_filter_params } = this.state;

        data_filter_params = {
            ...data_filter_params,
            [searchName]: event.target.value,
        }
        this.setState({
            data_filter_params: data_filter_params,
            searchTerm: event.target
        });

        if (event.target.value === '' && this.state.prevSearchTerm) {
            this.setState({
                data_filter_params: {
                  ...data_filter_params,
                  [searchName]: '',
                },
                prevSearchTerm: ''
              }, () =>
                this.search()
              );
        }
    }

    handleKeyDown = (e) => {
        this.search();
    };


    render() {
        const { loading, data, columns, totalPagination, pageSize, page } = this.state
        return (
            <>
                <Row gutter={[16, 16]}>
                    <Col span={8}>
                        <TitleLevel3 label={i18next.t("company")} />
                    </Col>
                    <Col span={16} style={{ textAlign: "end" }}>
                        <MvButton path={'companycreate'} icon={<PlusCircleFilled />}></MvButton>
                    </Col>
                    <Col span={4}>
                        <EdSearchBox
                            size="middle"
                            onChange={(values) =>
                                this.changeSearchName(values, "fullName")}
                            placeholder="SearchByName"
                            onPressEnter={this.handleKeyDown}
                        />
                    </Col>
                    <Col span={4}>
                        <EdSearchBox
                            size="middle"
                            onChange={(values) =>
                                this.changeSearchName(values, "phoneNumber")}
                            placeholder="SearchByPhoneNumber"
                            onPressEnter={this.handleKeyDown}
                        />
                    </Col>
                    <Col span={4}>
                        <EdSearchBox
                            size="middle"
                            onChange={(values) =>
                                this.changeSearchName(values, "companyName")}
                            placeholder="SearchByCompany"
                            onPressEnter={this.handleKeyDown}
                        />
                    </Col>
                    <Col span={12}>
                        <Button size="large" shape="round" onClick={() => this.search()} type="primary" >Search</Button>
                    </Col>
                    <Col span={24}>
                        {
                            loading ? (
                                <Skeleton active />) : (
                                <Table
                                    pagination={{
                                        position: "top" | "bottom",
                                        total: totalPagination,
                                        current: page,
                                        onChange: this.handlePaginationChange,
                                        defaultPageSize: pageSize,
                                    }} dataSource={data} columns={columns} />
                            )
                        }
                    </Col>
                </Row>
            </>
        )
    }
}
export default compose(withRouter(CompanyPage))

